import { ThemeProvider } from '@mui/material'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import './index.css'

import StyledToastContainer from 'components/StyledToastContainer'
import SseProvider from 'providers/SseProvider'
import App from './App'
import ErrorApp from './ErrorApp'

import { AUTH_CLIENT_ID } from 'assets/const'
import { KeycloakConfig } from 'keycloak-js'
import AuthService from 'services/AuthService'
import { ConfigurationService } from 'services/configuration'
import { setConfiguration } from 'store/app-reducer'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { theme } from './theme'

const container = document.getElementById('root')!
const root = createRoot(container)

const renderErrorApp = () =>
  root.render(
    <ThemeProvider theme={theme}>
      <ErrorApp />
    </ThemeProvider>
  )

const renderApp = () =>
  root.render(
    <ThemeProvider theme={theme}>
      <StyledToastContainer />
      <Provider store={store}>
        <SseProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </SseProvider>
      </Provider>
    </ThemeProvider>
  )

const initApp = async () => {
  const configuration = await ConfigurationService.getConfiguration()

  if (!configuration) {
    renderErrorApp()
    return
  }

  store.dispatch(setConfiguration(configuration))

  const clientId = AUTH_CLIENT_ID + configuration.sso.clientSuffix

  const keycloakConfig: KeycloakConfig = { ...configuration.sso, clientId }

  AuthService.initKeycloak(renderApp, keycloakConfig)
}

initApp()
reportWebVitals()
