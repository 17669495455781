import Box, { BoxProps } from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

export default function LinearIndeterminate(props: BoxProps) {
  return (
    <Box sx={{ width: '100%' }} {...props}>
      <LinearProgress />
    </Box>
  )
}
