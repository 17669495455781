
import { Link } from 'react-router-dom'
import { Box, Drawer } from '@mui/material'

import ProfileNavigation from 'pages/profile/ProfileNavigation'
import WalletsNavigation from './WalletsNavigation'

import { backgroundGradient, drawerWidth, headerHeight } from 'consts/layout'
import { DrawerVariant } from 'assets/const'
import { DrawerVariantType } from 'types/drawer'

import { ReactComponent as Logo } from 'assets/svg/logo/LogoCw.svg'


interface Props {
  layout: DrawerVariantType
}

const SideBar = ({ layout }: Props) => {

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        height: '100dvh-100px',
        display: { xs: 'none', md: 'block' },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          borderRadius: '0',
          height: '100dvh-100px',
          background: backgroundGradient,
          borderRight: 'none',
        },
      }}
      variant='persistent'
      anchor='left'
      open={true}
    >
      <Box
        sx={{
          height: headerHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& a': {display: 'flex'}
        }}
      >
        <Link to='/' aria-label='Home page'> <Logo/> </Link>
      </Box>
      {layout === DrawerVariant.Dashboard && <WalletsNavigation />}
      {layout === DrawerVariant.Profile && <ProfileNavigation />}
    </Drawer>
  )
}

export default SideBar
