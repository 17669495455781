import { SxProps, Theme } from '@mui/material'

const wrapper: SxProps = {
  p: 2,
  borderRadius: '8px',
  border: '1px solid',
  borderColor: 'gray.main',
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  minWidth: {xs: 'auto', sm: '400px'}
}

const getGridContainerSx =  {
  gridTemplateColumns : {xs: '1fr', sm: 'repeat(2, 1fr)'}
}

const gridItem: SxProps = {
  display: 'flex',
  py: 1,
  gap: 1
}

const tableWrapper: SxProps = {
  p: 2,
}

const label: SxProps = {
  fontWeight: '400',
  fontSize: '14px',
}

const value: SxProps = {
  fontWeight: '500',
  fontSize: '14px',
}

const transactionData = {
  display: 'flex',
  gap: 1,
  alignItems: 'end',
}

const transactionsWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}

const transactionLabel: SxProps = {
  fontSize: '12px'
}

const tableContainer: SxProps<Theme> = {
  pr: 0
}

const Styles = {
  label,
  value,
  wrapper,
  getGridContainerSx,
  gridItem,
  tableContainer,
  tableWrapper,
  transactionLabel,
  transactionData,
  transactionsWrapper
}

export default Styles