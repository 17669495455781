
import { FC, MouseEvent, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { theme } from 'theme';

import EditWallet from '../EditWallet';
import ConfirmModal from 'components/ConfirmModal';

import { Wallet } from 'types/wallet';
import { useDeleteWalletMutation } from 'services/wallets';

import { ReactComponent as EditWalletIcon } from 'assets/svg/buttons/EditIcon.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/buttons/CloseWallet.svg'

import s from '../styles'


interface Props {
  wallet: Wallet
}


const WalletSettings: FC<Props> = ({wallet}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modal, setModal] = useState<null | 'EDIT_MODAL' | 'CLOSE_MODAL'>(null)
  const [close, {isLoading}] = useDeleteWalletMutation()
  const open = Boolean(anchorEl);

  const handleEditWallet = () => {
    setAnchorEl(null);
    setModal('EDIT_MODAL')
  };

  const handleCloseWallet = () => {
    setAnchorEl(null);
    setModal('CLOSE_MODAL')
  };

  const closeWallet = () => {
    close(wallet.id).unwrap().then(() => setModal(null))
  }

  return (
    <>
      <SettingsIcon
        fontSize='large'
        sx={s.settingIcon}
        onClick={(event: MouseEvent<SVGSVGElement>) => setAnchorEl(event.currentTarget as unknown as HTMLElement)}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={s.settingsMenu}
      >
        <MenuItem onClick={handleEditWallet}>
          <EditWalletIcon color={theme.palette.primary.main}/>
          Edit wallet name
        </MenuItem>
        <MenuItem disabled={wallet.balance === '0' ? false : true} onClick={handleCloseWallet}>
          <CloseIcon color={theme.palette.primary.main}/>
          Close wallet
        </MenuItem>
      </Menu>

      {modal === 'EDIT_MODAL' && (
        <EditWallet handleClose={() => setModal(null)} wallet={wallet} />
      )}
      {modal === 'CLOSE_MODAL' && (
        <ConfirmModal
          variant='confirm'
          onConfirm={closeWallet}
          onClose={() => setModal(null)}
          text='Are you sure you want to close your wallet?'
          loading={isLoading}
        />
      )}
    </>
  )
}

export default WalletSettings
