import { FC } from 'react'
import { Box } from '@mui/material'

import WalletsNavigation from 'components/Sidebar/WalletsNavigation'
import MobileProfileNavigation from 'pages/profile/MobileProfileNavigation'

import { backgroundGradient } from 'consts/layout'
import { DrawerVariant } from 'assets/const'
import { DrawerVariantType } from 'types/drawer'


interface Props {
  layout: DrawerVariantType
}

const MobileBar: FC<Props> = ({layout}) => {

  return (
    <Box sx={{background: backgroundGradient}}>
      { layout === DrawerVariant.Dashboard && <WalletsNavigation />}
      { layout === DrawerVariant.Profile && <MobileProfileNavigation />}
    </Box>
  )
}

export default MobileBar