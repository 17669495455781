import { FC } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'


import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useMediaSize } from 'hooks/useMediaSize';




interface Props {
  checked: boolean
  setChecked: (value: boolean) => void
}

const SidebarCheckbox: FC<Props> = ({
  checked,
  setChecked,
}) => {
  const {isMobileDevice} = useMediaSize()
  const handleChange =  (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <Box>
      {!isMobileDevice &&
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: '#FFFFFF',
          }}
        >
          My wallet
        </Typography>
      }
      <FormControlLabel
        label='Hide wallets with zero balance'
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            size='small'
            checkedIcon={<CheckBoxOutlinedIcon sx={{color: 'text.contrast'}}/>}
            sx={{color: 'text.contrast', width: '26px', ml: '6px'}}
          />
        }
        sx={{
          color: theme => theme.palette.text.contrast,
          '& .MuiTypography-root': {
            color: theme => theme.palette.text.contrast,
            fontWeight: '400',
            fontSize: '12px',
            // minWidth: '182px'
          }
        }}
      />
    </Box>
  )
}

export default SidebarCheckbox