import { FC } from 'react'

import AddIcon from '@mui/icons-material/Add'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface Props {
  summary: string
  details: string
}

const expandIcon = (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      m: '1px auto',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      border: '1px solid',
      borderColor: 'primary.main'
    }}
  >
    <AddIcon sx={{ color: 'primary.main' }} />
  </Box>
)

const FaqAccordion: FC<Props> = ({ summary, details }) => {
  return (
    <MuiAccordion
      disableGutters
      elevation={0}
      sx={{
        boxShadow: 'none',
        // border: 'none',
        // borderBottom: '1px #DFDFDF solid',
        '&.MuiPaper-root': { p: '12px 24px' },
        '&.MuiPaper-root:before': { display: 'none' },
        '&.MuiButtonBase-root-MuiAccordionSummary-root': { p: '0' },
      }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        sx={{
          p: '0',
          '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
            transform: 'rotate(45deg)',
          },
        }}
      >
        <Typography sx={{ fontWeight: '600' }}>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: 'justify', px: '0' }}>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5rem',
            textAlign: 'left',
          }}
        >
          {details}
        </Typography>
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default FaqAccordion
