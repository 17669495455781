import { FC } from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography'
import { Paper } from '@mui/material'
import { Link } from 'react-router-dom';

interface Props {
  text: string
  link: string
}

const TermsBox: FC<Props> = ({ text, link }) => {
  return (
    <Link to={link}>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          transition: 'background .2s',
          ':hover': {bgcolor: 'gray.light', transition: 'background .2s'}
        }}
      >
        <Typography>{text}</Typography>
        <ArrowForwardIosIcon sx={{width: '16px'}}/>
      </Paper>
    </Link>
  )
}

export default TermsBox
