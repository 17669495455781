import { Box, Container, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { Outlet } from 'react-router-dom'

import BackButton from '../../components/BackButton'


const Profile = () => {

  return (
    <Container disableGutters>
      <Typography variant="h1" sx={visuallyHidden}>
        Profile
      </Typography>
      <Box>
        <BackButton />
        <Box width='100%'>
          <Outlet />
        </Box>
      </Box>
    </Container>
  )
}

export default Profile