import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { baseApiUrl } from 'assets/const'
import AuthService from '../services/AuthService'

const baseQuery = fetchBaseQuery({
  baseUrl: baseApiUrl,
  prepareHeaders: async (headers, _) => {
    await AuthService.updateToken()
    const token = await AuthService.getToken()
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
      headers.set('Content-Type', 'application/json')
    }
    return headers
  },
})

export default baseQuery
