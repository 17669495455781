import { Box, IconButton, Menu, Tooltip, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Preloader from 'components/Preloader'

import { useMediaSize } from 'hooks/useMediaSize'
import { PSTemplateRequest } from 'types/template'
import { theme } from 'theme'

import {ReactComponent as DeleteIcon} from 'assets/svg/buttons/DeleteIcon.svg'
import { ReactComponent as EditIcon } from 'assets/svg/buttons/EditIcon.svg'

import s from './styles'
import { useRef, useState } from 'react';


interface Props {
  icon: string
  index: number
  template: PSTemplateRequest
  isDeleting: boolean
  handleEdit: (template: PSTemplateRequest) => void
  handleDelete: (id: string) => void
}

const TemplateProfileCard = ({
  icon,
  index,
  template,
  isDeleting,
  handleDelete,
  handleEdit,
}: Props) => {
  const { isPhone } = useMediaSize()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleToggle = () => {
    setAnchorEl((prev) => (prev ? null : containerRef.current))
  }

  return (
    <Box sx={{ ...s.card(!(index % 2)) }}>
      {isDeleting && <Preloader />}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          gap: '10px',
        }}
      >
        <Box sx={s.icon}>{icon && <img src={icon} alt='icon' />}</Box>
        <Typography
          sx={{
            fontSize: 14,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {template.name}
        </Typography>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          display: 'flex',
          gap: '5px',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        {isPhone ?
          <IconButton
            component='div'
            ref={containerRef}
            // size={isPhone ? 'small' : 'medium'}
            onClick={handleToggle}
          >
            <MoreVertIcon/>
          </IconButton>
          :
          <>
            <Tooltip title='Edit' placement={isPhone ? 'left' : 'top'} sx={{mr: 0}}>
              <IconButton
                edge='end'
                onClick={() => handleEdit(template)}
              >
                <EditIcon color={theme.palette.gray.dark}/>
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete' placement={isPhone ? 'left' : 'top'}>
              <IconButton
                disabled={isDeleting}
                onClick={() => handleDelete(template.id ?? '')}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      </Box>
      <Menu
        autoFocus={false}
        id='nav-menu'
        open={!!anchorEl && isPhone}
        onClick={handleToggle}
        anchorEl={anchorEl}
        MenuListProps={{
          disablePadding: true,
        }}
        PaperProps={{}}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <IconButton onClick={() => handleEdit(template)}>
          <EditIcon/>
        </IconButton>
        <IconButton disabled={isDeleting} onClick={() => handleDelete(template.id ?? '')}>
          <DeleteIcon/>
        </IconButton>
      </Menu>
    </Box>
  )
}

export default TemplateProfileCard
