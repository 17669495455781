import { SxProps } from '@mui/material'
import { theme } from 'theme'

const accordion: SxProps = {
  borderRadius: '8px !important',
  '& .MuiPaper-root-MuiAccordion-root': {
    borderTopLeftRadius: '20px',
  },
  padding: '24px',
  '& .MuiAccordionSummary-root': {
    padding: '0',
    minHeight: '0px'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionDetails-root': {
    padding: '0',
    marginTop: '16px'
  },
}

const header: SxProps = {
  fontWeight: 500,
  fontSize: { xs: '20px', sm: '24px' },
  display: 'flex',
  gap: 1,
  alignItems: 'center',
  px: '6px'
}

const headerTitle: SxProps = {
  fontWeight: 500,
  mr: 1,
  fontSize: { xs: '20px', sm: '24px' },
  lineHeight: '28px'
}

const filterChip: SxProps = {
  bgcolor: theme.palette.primary.light,
  height: '28px',
  fontSize: '14px',
  fontWeight: '400',
  maxWidth: '200px',
  'svg': {
    fill: theme.palette.primary.main
  },
  '.MuiChip-deleteIconMedium': {  width: '18px'},
  ':hover': {bgcolor: theme.palette.primary.light, cursor: 'auto'}
}

const buttonWrapper: SxProps = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  gap: '12px',
  justifyContent: { sm: 'space-around', md: 'space-between' },
  alignItems: 'center',
  '.Mui-Grid-root': {gap: '12px'}
}

const button: SxProps = {
  display: 'block',
  fontWeight: 700,
  flex: 1
}

const Styles = {
  accordion,
  header,
  headerTitle,
  filterChip,
  buttonWrapper,
  button,
}

export default Styles