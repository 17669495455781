import { format } from 'date-fns';

import { Currency } from 'types/currency'

const locale = navigator.language

export const formatAmount = (
  amount: string,
  code: string,
  options?: Partial<Currency>
): string => {
  const numberAmount = Number(amount)

  if (isNaN(numberAmount)) return amount

  if (options?.isCrypto) {
    const userAmount = new Intl.NumberFormat(locale, {
      minimumFractionDigits: options?.maxDecimals,
    }).format(numberAmount)

    if (!options?.symbol)
      return options?.symbolBefore
        ? `${code} ${userAmount}`
        : `${userAmount} ${code}`

    return options?.symbolBefore
      ? `${options?.symbol}${userAmount}`
      : `${userAmount} ${options?.symbol}`
  }

  const userAmount = new Intl.NumberFormat(locale, {
    minimumFractionDigits: options?.maxDecimals,
    currency: code,
    style: 'currency',
    currencyDisplay: options?.symbol ? 'symbol' : 'code',
  }).formatToParts(numberAmount)

  return userAmount.reduce((acc, part) => {
    switch (part.type) {
      case 'currency':
        return acc + (options?.symbol ?? part.value)
      default:
        return acc + part.value
    }
  }, '')
}

export const formatBalanceWithDecimals = (num: string | undefined, maxDecimals?: number | undefined): string => {
  if (num === undefined) return ''
  const number: number = parseFloat(num);
  let formattedNumber: string;
  if (maxDecimals !== undefined) {
    formattedNumber = number.toFixed(maxDecimals).replace(/0+$/, '')
    if (formattedNumber.endsWith('.')) {
      formattedNumber = formattedNumber.slice(0, -1)
    }
  } else {
    formattedNumber = number.toString();
  }
  const [integerPart, decimalPart] = formattedNumber.split('.');
  const formattedIntegerPart: string = integerPart
    .split('')
    .reverse()
    .map((digit, index) => ((index % 3 === 0) && index > 0) ? `${digit} ` : digit)
    .reverse()
    .join('');

  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : `${formattedIntegerPart}`;
};

export const formatAmountForTable = (amount: string, currency: undefined | string) => {
  return `${+amount > 0 ? '+' : '-'} ${currency} ${Math.abs(+amount)}`
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, 'dd.MM.yyyy');
}

export const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, 'hh:mm a');
}