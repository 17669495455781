import { FC } from 'react'
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
  withLeftMargin?: boolean
}
const Preloader: FC<Props> = ({withLeftMargin}) => {
  return (
    <Backdrop
      open
      invisible
      sx={{ zIndex: 10, pl: {md: withLeftMargin ? '250px' : 0}}}
    >
      <CircularProgress/>
    </Backdrop>
  )
}

export default Preloader
