import Box from '@mui/material/Box'

import ProfileNavigationItem from './ProfileNavigationItem'

import { AppRoute } from 'assets/const'

import { ReactComponent as ProfileIcon } from 'assets/svg/profile/Profile.svg'
import { ReactComponent as TemplatesIcon} from 'assets/svg/profile/Templates.svg'
import { ReactComponent as AuthIcon} from 'assets/svg/profile/Auth.svg'

import s from './styles'


export const profileNavigationItems = [
  {label: 'Information', path: AppRoute.Profile, icon: <ProfileIcon/>},
  {label: 'Withdraw templates', path: `profile/${AppRoute.Templates}`, icon: <TemplatesIcon/>},
  {label: '2-factor authentication', path: `profile/${AppRoute.AuthSettings}`, icon: <AuthIcon/>},
  // {name: 'IP Whitelist', path: `profile/${AppRoute.IpWhitelist}`, icon: <ContactMailIcon/>},
]

const ProfileNavigation = () => {
  return (
    <Box sx={s.profile}>
      {profileNavigationItems.map(item =>
        <ProfileNavigationItem key={item.label} label={item.label} path={item.path} icon={item.icon}/>
      )}
    </Box>
  )
}

export default ProfileNavigation
