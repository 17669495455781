import { FC } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from 'components/Modal'

import { formatDate } from 'utils/intlFormat'
import { statusColors, getOperationValue } from 'utils/operation'
import { Withdraw } from 'types/operation'

import { OperationStatus, OperationType } from 'assets/const'

import s from 'components/OperationInfoModal/styles'


interface Props {
  withdraw: Withdraw
  onClose: () => void
}

const WithdrawInfoModal: FC<Props> = ({withdraw, onClose}) => {

  return (
    <Modal open handleClose={onClose} title='Billing information'>
      <Box sx={s.wrapper}>
        <Box sx={s.gridItem}>
          <Typography sx={s.label}>Updated:</Typography>
          <Typography sx={s.value}>
            {formatDate(withdraw.updated)}
          </Typography>
        </Box>
        <Box sx={s.gridItem}>
          <Typography sx={s.label}>Id:</Typography>
          <Typography sx={s.value}>
            {getOperationValue(withdraw.id, OperationType)}
          </Typography>
        </Box>
        <Box sx={s.gridItem}>
          <Typography sx={s.label}>Amount:</Typography>
          <Typography sx={s.value}>
            {withdraw.amount}
          </Typography>
        </Box>
        <Box sx={s.gridItem}>
          <Typography sx={s.label}>Currency:</Typography>
          <Typography sx={s.value}>
            {withdraw.currency}
          </Typography>
        </Box>
        <Box sx={s.gridItem}>
          <Typography sx={s.label}>Commission:</Typography>
          <Typography sx={s.value}>{withdraw.commission}</Typography>
        </Box>
        <Box sx={ s.gridItem }>
          <Typography sx={s.label}>Payment System:</Typography>
          <Typography sx={s.value}>{withdraw.psCode}</Typography>
        </Box>
        <Box sx={s.gridItem}>
          <Typography sx={s.label}>Status:</Typography>
          <Typography
            sx={{
              ...s.value,
              color: statusColors[withdraw.status].main,
            }}
          >
            {getOperationValue(withdraw.status, OperationStatus)}
          </Typography>
        </Box>
        <Box sx={s.gridItem }>
          <Typography sx={s.label}>Feedback:</Typography>
          <Typography sx={s.value}>{withdraw.fee}</Typography>
        </Box>
        <Box sx={s.gridItem}>
          <Typography sx={s.label}>Comment:</Typography>
          <Typography sx={s.value}>{withdraw.comment}</Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default WithdrawInfoModal
