import { createAction } from '@reduxjs/toolkit'

export enum ActionType {
  UpdateSavedTemplateSuccess = 'UPDATE_SAVED_TEMPLATE_SUCCESS',
  UpdateSavedTemplateFailed = 'UPDATE_SAVED_TEMPLATE_FAILED',
  WithdrawSuccess = 'WITHDRAW_SUCCESS',
  WithdrawFailed = 'WITHDRAW_FAILED',
}

export const updateSavedTemplateSuccessAction = createAction(
  ActionType.UpdateSavedTemplateSuccess
)
export const updateSavedTemplateFailedAction = createAction(
  ActionType.UpdateSavedTemplateFailed
)
export const withdrawSuccessAction = createAction(ActionType.WithdrawSuccess)
export const withdrawFailedAction = createAction(ActionType.WithdrawFailed)
