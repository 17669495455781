import React, { FC, CSSProperties } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Controller } from 'react-hook-form'

import { formatDate } from 'utils/intlFormat'

interface DatePickerProps {
  control: any
  name: string
  label: string
  errors?: any
  readonly?: boolean
  disableHighlightToday?: boolean
  disableFuture?: boolean
  disablePast?: boolean
  inputStyle?: CSSProperties
  size?: 'small' | 'medium'
}

const CustomDatePicker: FC<DatePickerProps> = ({
  control,
  errors,
  name,
  label,
  inputStyle,
  size = 'medium',
  ...rest
}) => {

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name, onBlur } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label={label}
            components={{
              OpenPickerIcon: CalendarMonthIcon,
            }}
            OpenPickerButtonProps={{
              sx: {
                color: 'primary.main',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '10px',
              },
            }}
            value={value}
            onChange={onChange}
            {...rest}
            renderInput={(params) => (
              <TextField
                {...params}
                size={size}
                onBlur={onBlur}
                value={value ? formatDate(value) : ''}
                fullWidth
                className='inputRounded'
                error={errors[name]}
                helperText={errors[name]?.message}
                inputProps={{
                  style: inputStyle,
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  )
}

export default CustomDatePicker