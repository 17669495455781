import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Currency } from 'types/currency'
import baseQuery from '../api'

export const currenciesApi = createApi({
  reducerPath: 'currenciesApi',
  baseQuery,
  tagTypes: ['Currencies', 'CurrenciesType'],
  endpoints: (build) => ({
    getCurrency: build.query<Currency, string>({
      query: (currencyCode) => ({
        url: `currency/${currencyCode}`,
        method: 'GET',
      }),
      providesTags: ['Currencies'],
      transformResponse: (response: {currency: Currency}) => response.currency,
    }),
  }),
})

export const { useGetCurrencyQuery } = currenciesApi
