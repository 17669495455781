import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Tab,
  Tabs,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { FC, SyntheticEvent, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Option } from 'components/Select'
import TextInput from 'components/TextInput'
import CustomWalletsSelect from './CustomWalletsSelect'

import { Currency } from 'types/currency'
import { TransferReqData } from 'types/requests'
import { Wallet } from 'types/wallet'
import { transferSchema } from './validation'

import { useMediaSize } from 'hooks/useMediaSize'


interface FormValues {
  fromWallet: string
  toWallet: string
  toAccount: string
  amount: string
  description: string
}

interface Props {
  handleTransferSubmit: (data: TransferReqData) => void
  wallet: Wallet
  wallets: Wallet[]
  currency: Currency
}

const AccountTransfer: FC<Props> = ({
  wallet,
  wallets,
  currency,
  handleTransferSubmit,
}) => {
  const [activeTab, setActiveTab] = useState<'toWallet' | 'toAccount'>('toWallet')
  const { isPhone } = useMediaSize()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<FormValues>({
    resolver: yupResolver(transferSchema),
    mode: 'onBlur',
    context: {
      maxDecimals: currency?.maxDecimals,
      balance: wallet?.balance,
      minValidAmount: 0,
      activeTab: activeTab,
    },
    defaultValues: {
      fromWallet: wallet.id,
      toWallet: '',
      toAccount: '',
      amount: '',
      description: '',
    },
  })

  const selectedWalletIdTo = watch('toWallet')
  const selectedWalletIdFrom = watch('fromWallet')

  const walletsFromTransfer: Option[] = useMemo(
    () =>
      wallets.map((wallet) => ({ value: wallet.id, label: wallet.name })),
    [wallet?.id]
  )

  const walletsToTransfer: Option[] = useMemo(
    () =>
      wallets
        ?.filter((w) => selectedWalletIdFrom !== w.id)
        .map((wallet) => ({ value: wallet.id, label: wallet.name })),
    [wallet?.id, selectedWalletIdFrom]
  )

  const onSubmit = (formData: FormValues) => {
    if (!wallet?.id) return
    const data = {
      id: formData.fromWallet,
      amount: formData.amount,
      currency: wallet.currency,
      recipient: activeTab === 'toAccount' ? formData.toAccount : undefined,
      destinationWalletId: activeTab === 'toWallet' ? formData.toWallet : undefined,
      description: formData.description,
    }
    handleTransferSubmit(data)
  }

  return (
    <Box
      component='form'
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: {xs: 2, sm: 3},
        maxWidth: '662px',
        width: '100%',
      }}
    >
      <Typography variant={isPhone ? 'h3' : 'h2'} mb={isPhone ? 0 : 1}>Transfer</Typography>

      <Tabs
        centered
        variant='fullWidth'
        value={activeTab}
        onChange={(event: SyntheticEvent, newValue: 'toWallet' | 'toAccount') => setActiveTab(newValue)}
      >
        <Tab value='toWallet' label='Between your wallets' />
        <Tab value='toAccount' label='To another Clever Wallet user ' />
      </Tabs>

      <Typography variant={isPhone ? 'h4' : 'h3'}>Please provide transfer details</Typography>

      <Paper sx={{p: {xs: 2, sm: 3}, flexGrow: 1}}>
        <Typography variant='h4' mb={2}>Where to transfer from</Typography>
        <CustomWalletsSelect
          control={control}
          name='fromWallet'
          label=''
          wallets={wallets}
          selectedWalletId={selectedWalletIdFrom}
          options={walletsFromTransfer}
          errors={''}
          currency={currency}
        />
      </Paper>

      <Paper sx={{p: {xs: 2, sm: 3}}}>
        {activeTab === 'toWallet' ? (
          <>
            <Typography variant='h4' mb={2}>Where are we transferring</Typography>
            <CustomWalletsSelect
              control={control}
              name='toWallet'
              label=''
              wallets={wallets}
              selectedWalletId={selectedWalletIdTo}
              options={walletsToTransfer}
              errors={''}
              currency={currency}
            />
          </>
        ) : (
          <>
            <Typography variant='h4' mb={2}>Select user identification</Typography>
            <TextInput
              name='toAccount'
              label=''
              required
              fullWidth
              control={control}
              errors={errors}
              sx={{bgcolor: 'gray.light'}}
            />
          </>
        )}
      </Paper>

      <Paper sx={{p: {xs: 2, sm: 3}}}>
        <Typography variant='h4' mb={2}>Transfer amount</Typography>
        <TextInput
          name='amount'
          label=''
          placeholder={`Amount ${currency.symbol}`}
          fullWidth
          required
          control={control}
          errors={errors}
          InputProps={{ sx: { background: 'gray.light' } }}
          sx={{bgcolor: 'gray.light'}}
        />
      </Paper>

      {activeTab === 'toAccount' &&
        <Paper sx={{p: {xs: 2, sm: 3}}}>
          <Typography variant='h4' mb={2} sx={{'span': {fontSize: 12, verticalAlign: 1.5}}}>Comment <span>(optional)</span> </Typography>
          <TextInput
            name='description'
            label=''
            fullWidth
            control={control}
            errors={errors}
            sx={{bgcolor: 'gray.light'}}
          />
        </Paper>
      }
        <Button
          variant='contained'
          type='submit'
          disabled={!isValid || !isDirty}
          fullWidth={isPhone}
          sx={{width: { sm: '200px'}}}
        >
          Continue
        </Button>
      {/* </Box> */}
    </Box>

  )
}

export default AccountTransfer