import { SxProps, Theme } from '@mui/material'

const card = (even: boolean): SxProps<Theme> => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme => even ? theme.palette.gray.light : 'null',
  borderRadius: '8px',
  padding: '16px',
  width: '100%',
  minWidth: '0',
})

const icon: SxProps = {
  bgcolor: 'contrast.main',
  width: '60px',
  height: '40px',
  border: '1px solid',
  borderColor: 'primary.light',
  borderRadius: '8px',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  '& > img': {
    maxHeight: '90%',
    maxWidth: '90%',
  }
}

const cardNumber: SxProps = {
  margin: '0 15px',
}


const Styles = {
  card,
  icon,
  cardNumber,
}

export default Styles



