import { yupResolver } from '@hookform/resolvers/yup'
import {Box, Button} from '@mui/material'
import { DefaultValues, FieldValues, useForm } from 'react-hook-form'
import { AnyObjectSchema } from 'yup'

import Modal from 'components/Modal'
import Select from 'components/Select'
import TextInput from 'components/TextInput'
import DatePicker from 'components/DatePicker'

import { OperationsFilterParams, WithdrawFilterParams } from 'types/operation'


interface Props<T extends OperationsFilterParams & WithdrawFilterParams> {
  params: T
  statusOptions: { label: string; value: string }[]
  selectOptions: { label: string; value: string }[]
  selectLabel: string
  selectName: string
  schema: AnyObjectSchema
  clearParams: () => void
  setParams: (params: T) => void
  onClose: () => void
  setCountOfFilters: (num: number) => void
}

const FilterModal = <T extends OperationsFilterParams & WithdrawFilterParams>({
  params,
  statusOptions,
  selectOptions,
  selectLabel,
  selectName,
  schema,
  setParams,
  clearParams,
  onClose,
  setCountOfFilters
}: Props<T>) => {
  const isAmountFilter = Object.hasOwn(params, 'maxAmount')

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: params as DefaultValues<T>,
  })

  const resetHandler = () => {
    reset()
    clearParams()
    setCountOfFilters(0)
    onClose()
  }

  const onSubmit = (values: FieldValues) => {
    setParams(values as T)
    setCountOfFilters(Object.values(values).filter(value => value ).length)
    onClose()
  }

  return (
    <Modal
      open={true}
      handleClose={onClose}
      title='Filter'
      >
        <Box
          component='form'
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Select
            control={control}
            name={selectName}
            label={selectLabel}
            options={selectOptions}
            errors={errors}
          />
          <Select
            control={control}
            name='status'
            label='Status'
            options={statusOptions}
            errors={errors}
          />
          <Box display='flex' gap={2}>
            <DatePicker
              name='dateFrom'
              label='From'
              control={control}
              errors={errors}
            />
            <DatePicker
              name='dateTo'
              label='Till'
              control={control}
              errors={errors}
            />
          </Box>

          {isAmountFilter && (
            <TextInput
              control={control}
              name='maxAmount'
              label='Amount'
              errors={errors}
              FormHelperTextProps={{
                sx: { mr: { md: 'auto' }, ml: { md: 'auto' } },
              }}
            />
          )}

          <Box display='flex' gap={2} mt={1}>
            <Button
              disabled={!isValid || !isDirty}
              variant='contained'
              sx={{flex: 1}}
              type='submit'
            >
              Apply
            </Button>

            <Button
              type='reset'
              variant='outlined'
              onClick={resetHandler}
              sx={{flex: 1}}
            >
              Clear
            </Button>
          </Box>

        </Box>
    </Modal>
  )
}

export default FilterModal
