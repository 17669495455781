import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { UpdateWalletReq } from 'types/requests'
import { WalletListResponse } from 'types/responses'
import { NewWallet, Wallet, AvailableWallets, fetchWalletsReq } from 'types/wallet'
import { Currency } from 'types/currency'
import baseQuery from '../api'

export const walletsApi = createApi({
  reducerPath: 'walletsApi',
  baseQuery,
  tagTypes: ['Wallets', 'Wallet', 'Currencies'],
  endpoints: (build) => ({

    fetchAllWallets: build.query<Wallet[], fetchWalletsReq>({
      query: ({currency='', excludeEmpty=false}) => ({
        url: `wallets`,
        method: 'POST',
        body: {currency, excludeEmpty},
      }),
      providesTags: ['Wallets'],
      transformResponse: (response: WalletListResponse) =>
        response.list,
    }),

    getWallet: build.query<Wallet, string>({
      query: (id) => `wallet/${id}`,
      providesTags: () => ['Wallet'],
    }),

    getAvailableWallets: build.query<AvailableWallets, void>({
      query: () => `wallets/types`,
      providesTags: () => ['Wallet'],
    }),

    getWalletsCurrencies: build.query<Currency[], void>({
      query: () => `wallets/currencies`,
      providesTags: () => ['Wallet', 'Currencies'],
      transformResponse: (response: {list: Currency[]}) =>
        response.list,
    }),

    addWallet: build.mutation({
      query: (wallet: NewWallet) => ({
        url: 'wallets/add',
        method: 'POST',
        body: wallet,
      }),
      invalidatesTags: ['Currencies'],
    }),

    updateWallet: build.mutation<Wallet, UpdateWalletReq>({
      query: ({ id, ...body }) => ({
        url: `wallet/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Wallets'],
    }),
    deleteWallet: build.mutation<void, string>({
      query: (id) => ({
        url: `wallet/${id}/delete`,
        method: 'POST',
      }),
      invalidatesTags: ['Wallets', 'Currencies'],
    }),

  }),
})

export const {
  useFetchAllWalletsQuery,
  useGetWalletQuery,
  useGetAvailableWalletsQuery,
  useGetWalletsCurrenciesQuery,
  useAddWalletMutation,
  useUpdateWalletMutation,
  useDeleteWalletMutation
} = walletsApi