import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Menu } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';

import UserInfo from './UserInfo'
import MenuLink from './UserInfo/MenuLink'
import MobileMenuLink from './UserInfo/MobileMenuLink'

import { useMediaSize } from 'hooks/useMediaSize'
import { DrawerVariantType } from 'types/drawer'

import { AppRoute } from 'assets/const'
import { ReactComponent as Logo } from 'assets/svg/logo/LogoCwSecondary.svg'
import { ReactComponent as LogoIcon } from 'assets/svg/logo/LogoCwIcon.svg'

import s from './styles'


interface Props {
  drawerVariant: DrawerVariantType | null
}


export const menuItems = [
  {label: 'Home', path: AppRoute.Home},
  {label: 'Payments', path: '#', disabled: true},
  {label: 'FAQ', path: AppRoute.Faq},
  {label: 'More', path: AppRoute.More},
]

const Header = ({ drawerVariant }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { isMobileDevice } = useMediaSize()
  const { isPhone } = useMediaSize()

  const handleToggle = () => {
    setAnchorEl((prev) => (prev ? null : containerRef.current))
  }

  return (
    <>
      <Box component={'header'} sx={s.headerWrapper}>
        {isPhone &&
          <Box ref={containerRef} onClick={handleToggle} sx={s.burgerWrapper}>
            <MenuIcon/>
          </Box>
        }
        <Box sx={s.logoWrapper}>
          {(!drawerVariant || isMobileDevice) &&
            <Link to='/'> {isMobileDevice ? <LogoIcon /> : <Logo />}  </Link>
          }
        </Box>

        <Box sx={s.headerContent(drawerVariant === 'Profile' ? 'left' : 'right')} ref={containerRef}>
            {!isPhone &&
              <Box sx={s.navMenu}>
                {menuItems.map(link =>
                  <MenuLink key={link.path} label={link.label} path={link.path} disabled={link.disabled}/>
                )}
              </Box>
            }
        </Box>
        <UserInfo />
      </Box>

      <Menu
        autoFocus={false}
        id='nav-menu'
        open={!!anchorEl && isPhone}
        onClick={handleToggle}
        anchorEl={anchorEl}
        MenuListProps={{
          disablePadding: true,
          sx: s.menuList,
        }}
        PaperProps={{
          elevation: 2,
          sx: s.navMenuPaper,
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {menuItems.map(link =>
          <MobileMenuLink key={link.label} label={link.label} path={link.path} disabled={link.disabled}/>
        )}
      </Menu>
    </>
  )
}

export default Header
