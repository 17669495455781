import { SxProps, Theme } from '@mui/material'


const profile: SxProps<Theme> = {
  display: 'inline-block',
  width: '100%',
  maxWidth: '256px',
}

const mainBox: SxProps<Theme> = {
  display: 'flex',
  flexDirection: {xs: 'column', sm: 'row'},
  alignItems: {xs: 'left', sm: 'center'},
  mt: 3
}

const firstBox: SxProps = {
  flex: {xs: 1, sm: 0},
  mr: {sm: 5, md: 7},
  my: {xs: 3, sm: 0},
  width: {xs: '100%', sm: 'auto'},
  maxWidth: {xs: '100%', sm: '35%', md: '270px', lg: '360px'},
}

const secondBox: SxProps = {
  flex: 1,
  width: {xs: '100%', sm: 'auto'},
  maxWidth: {xs: '100%', sm: '25%', md: '165px', lg: '220px'},
}

const label: SxProps = {
  fontWeight: '400',
  fontSize: '12px',
}

const value: SxProps = {
  fontWeight: 500,
  fontSize: '16px',
  marginTop: '6px',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const Styles = {
  profile,
  mainBox,
  firstBox,
  secondBox,
  label,
  value,
}

export default Styles



