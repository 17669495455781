import { Box, CircularProgress } from "@mui/material"


const PreloaderForInfiniteScroll = () => {

  return (
    <Box my={4} display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress/>
    </Box>
  )
}

export default PreloaderForInfiniteScroll