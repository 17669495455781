import { StandardTextFieldProps, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { FC, ReactNode } from 'react'
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form'


export interface Option {
  label: string
  value: string
}

interface Props extends StandardTextFieldProps {
  control: any
  name: string
  label: string
  errors: any
  options: Option[]
  disabled?: boolean
  size?: 'small' | 'medium'
  renderValue?: ReactNode
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
      >
    | undefined
}

const Select: FC<Props> = ({
  control,
  errors,
  name,
  label,
  options,
  disabled,
  rules,
  renderValue,
  size = 'medium',
  ...rest
}) => {

  return (
    <FormControl sx={{ minWidth: '100%', maxWidth: '100%' }} size='medium'>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, name, value, ref } }) => (
          <TextField
            onChange={onChange}
            value={value || ''}
            name={name}
            inputRef={ref}
            label={label}
            size={size}
            fullWidth
            error={!!errors[name]}
            helperText={errors[name]?.message}
            className='inputRounded'
            select
            disabled={disabled}
            defaultValue={''}
            SelectProps={{
              renderValue: renderValue ? () => renderValue || value : undefined,
              sx: {textTransform: 'capitalize'}
            }}
            sx={{
              '& .MuiSelect-icon': {
                // color: 'primary.main',
              },
            }}
            {...rest}
          >
            {options?.map((o, index) => (
              <MenuItem key={index} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </FormControl>
  )
}

export default Select