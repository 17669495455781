import compareAsc from 'date-fns/compareAsc'
import compareDesc from 'date-fns/compareDesc'
import * as yup from 'yup'

export const filterWithdrawSchema = yup.object().shape({
  dateFrom: yup
    .string()
    .nullable()
    .notRequired()
    .test('dateBefore', 'Please enter a valid date', (value, testContext) => {
      const dateTo = testContext.parent.dateTo
      return !!value && !!dateTo
        ? compareAsc(new Date(value), new Date(dateTo)) <= 0
        : true
    }),
  dateTo: yup
    .string()
    .nullable()
    .notRequired()
    .test('dateBefore', 'Please enter a valid date', (value, testContext) => {
      const dateFrom = testContext.parent.dateFrom
      return !!value && !!dateFrom
        ? compareDesc(new Date(value), new Date(dateFrom)) <= 0
        : true
    }),
  paymentSystem: yup.string().nullable().notRequired(),
  status: yup.string().nullable().notRequired(),
  // disabled filter params
  // massage: yup.string().nullable().notRequired(),
  //   maxAmount: yup
  //     .string()
  //     .nullable()
  //     .notRequired()
  //     .test('isPositiveInteger', 'Please enter a valid number', (value) => {
  //       const numberValue = Number(value)
  //       return !value
  //         ? true
  //         : Number.isInteger(Number(numberValue)) && numberValue > 0
  //     }),
})
