import { Button, Card, Typography } from '@mui/material'
import { getPaymentIconByPaymentSystem } from 'assets/const'
import { WalletsPaymentSystem } from 'types/wallet'

import s from '../styles'


interface Props {
  currency: string
  isActiveWallet: boolean
  handleClick: () => void
  isPaymentSystem?: boolean
}

const TableButton = ({ currency, isActiveWallet, handleClick, isPaymentSystem }: Props) => {
  return (
    <Button
      role={'div'}
      onClick={handleClick}
      sx={s.getWalletButton(isActiveWallet)}
      startIcon={
        <Card sx={s.walletCard(!isPaymentSystem)}>
          {isPaymentSystem ?
            <img height={30} src={getPaymentIconByPaymentSystem[currency as WalletsPaymentSystem]} alt='icon'/>
          :
            <Typography sx={s.cardCurrency}>{currency}</Typography>}
        </Card>
      }
    >
      <Typography sx={s.walletName}>{currency}</Typography>
      &nbsp;
      <Typography sx={{...s.walletName, color: 'text.primary'}}>wallet</Typography>
    </Button>
  )
}

export default TableButton
