import { FC, ReactNode } from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'

import s from '../styles'


export interface IProfileNavigationItem {
  label: string
  path: string
  icon: ReactNode
  onClose?: (() => void) | undefined
}

const ProfileNavigationItem: FC<IProfileNavigationItem> = ({label, path, icon, onClose}) => {
const navigate = useNavigate()
  const match = useMatch({
    path: path,
    end: true,
  })

  const handleChange = () => {
    navigate(path)
    onClose && onClose()
  }

  return (
    <Link to={path}>
      <Box sx={s.infoItem(!!match)} onClick={handleChange}>
        <SvgIcon sx={s.icon}>{icon}</SvgIcon>
        <Typography sx={s.typography}>{label}</Typography>
      </Box>
    </Link>
  )
}

export default ProfileNavigationItem