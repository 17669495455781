import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppRootStateType } from 'store'
import { Wallet } from 'types/wallet'

type InitialState = {
  currentWallet: Wallet | null
  isFilterExpended: boolean
}

const initialState: InitialState = {
  currentWallet: null,
  isFilterExpended: false,
}

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCurrentWallet(state, { payload }: PayloadAction<Wallet | null>) {
      state.currentWallet = payload
    },
    toggleIsFilterExpended(state) {
      state.isFilterExpended = !state.isFilterExpended
    },
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(
  //     walletsApi.endpoints.fetchAllWallets.matchFulfilled,
  //     (state, { payload }) => {
  //       const currentWallet = state.currentWallet
  //
  //       if (currentWallet === null) {
  //         state.currentWallet = payload[0]
  //         return
  //       }
  //       state.currentWallet =
  //         payload.find((p) => p.id === currentWallet.id) ?? payload[0]
  //     }
  //   )
  // },
})

export const dashboardReducer = slice.reducer
export const {
  // setCurrentWallet,
  toggleIsFilterExpended } = slice.actions

// export const getCurrentWallet = (state: AppRootStateType) =>
//   state.dashboard.currentWallet
export const getIsFilterExpended = (state: AppRootStateType) =>
  state.dashboard.isFilterExpended