import { Box, Button, Grid, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { theme } from 'theme'


interface Props {
  setImage: (image: string | null) => void
}

const MAX_FILE_SIZE = 4096

const fileValidator = (file: File) => {
  const validTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/x-icon',
    'image/bmp',
  ]
  if (validTypes.indexOf(file.type) === -1) {
    return {
      code: 'file-invalid-type',
      message: 'File type is invalid, please select an image file',
    }
  }

  if (Math.round(file.size / 1024) >= MAX_FILE_SIZE) {
    return {
      code: 'file-large-size',
      message: `File too big`,
    }
  }
  return null
}


const DropZone = ({ setImage }: Props) => {
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader()
      reader.onload = function (e) {
        const droppedImage = e?.target?.result
        if (typeof droppedImage === 'string') {
          setImage(droppedImage)
        }
      }
      reader.readAsDataURL(file)
      return file
    })
  }, [])

  const { getRootProps, getInputProps, open, isDragAccept, fileRejections } =
    useDropzone({
      maxFiles: 1,

      onDrop: handleDrop,
      noClick: true,
      noKeyboard: true,
      validator: fileValidator,
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg'],
        'image/jpg': ['.jpg'],
        'image/gif': ['.gif'],
        'image/x-icon': ['.ico'],
        'image/bmp': ['.bmp'],
      }
    })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Box key={file.name}>
      {errors.map((e) => (
        <Typography color='error' key={e.code}>
          {e.message}
        </Typography>
      ))}
    </Box>
  ))

  return (
    <>
      <Box
        sx={{
          minHeight: '284px',
          width: {xs: '287px', sm: '537px', md: '637px'},
          display: 'flex',
          mb: '24px',
          gap: '15px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: '1px',
          borderRadius: '8px',
          borderColor: isDragAccept ? 'success.main' : '#3699FF',
          borderStyle: 'dashed',
          transition: 'border 0.2s ease-in-out',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()}/>
        <Typography fontSize='14px'>Drag &quot;n&quot; drop image file here</Typography>
        <Typography color={theme.palette.gray.dark} textAlign='center' fontSize='12px'>
          Acceptable formats are png, jpg.<br/>Size no more than 4 MB
        </Typography>
        {fileRejectionItems}
      </Box>
      <Grid justifyContent={'left'}  container>
        <Button variant='contained' onClick={open} sx={{width: '200px', textTransform: 'none'}}>
          Select file
        </Button>
      </Grid>
    </>
  )
}
export default DropZone
