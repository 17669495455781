import { SxProps, Theme } from '@mui/material'


const headerRow: SxProps = {
  boxShadow: '-1px 3px 5px 1px #A4A4A440',
  boxSizing: 'border-box',
  margin: '2px 2px 24px'
}

const bodyRow: SxProps<Theme> = {
  '&:nth-of-type(odd)': {
    backgroundColor: (theme) => theme.palette.gray.light,
  },
  '& td': {
    border: 0,
    px: 2
  },
}

const row: SxProps = {
  display: 'grid',
  gridTemplateColumns: '18% 15% 22% 22% 22%'
}

const headerCell: SxProps = {
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: -1,
  display: 'inline-block'
}

const bodyCell: SxProps = {
  padding: '14px 5px',
  fontWeight: 500,
  flexWrap: 'wrap',
  height: 'auto',
}

const overflowedText: SxProps = {
  fontWeight: 500,
  overflowX: 'hidden',
  wordBreak: 'break-word',
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
}

const Styles = {
  headerCell,
  headerRow,
  bodyRow,
  bodyCell,
  row,
  overflowedText,
}

export default Styles
