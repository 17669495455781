import { FC, useEffect, useMemo, useState } from 'react'

import Filter from 'components/Filter'
import OperationTable from 'components/OperationTable'
import FilterModal from 'components/Filter/FilterModal'
import OperationCards from 'components/OperationCards'
import Preloader from 'components/Preloader'

import { OperationStatus } from 'assets/const'
import { OperationsFilterParams, OperationsPagingParams } from 'types/operation'
import { filterOperationsSchema } from './validation'
import { useMediaSize } from 'hooks/useMediaSize'
import { useFetchOperationTypesNamesQuery } from 'services/dictionaries'


const initialFilterParams: OperationsFilterParams = {
  dateFrom: undefined,
  dateTo: undefined,
  status: null,
  maxAmount: undefined,
  type: null,
}

const initialPagingParams: OperationsPagingParams = {
  pageIndex: 0,
  pageSize: 5,
  sortField: 'created',
  sortDirection: 'desc',
}

const statusToSelect = Object.entries(OperationStatus).map(([value, label]) => {
  return {
    label,
    value,
  }
})

interface Props {
  setFilterModal: (props: boolean) => void
  setCountOfFilters: (num: number) => void
  filterModal: boolean
}

const TabHistory: FC<Props> = ({filterModal, setFilterModal, setCountOfFilters}) => {
  const {isPhone} = useMediaSize()

  const [filterParams, setFilterParams] = useState(initialFilterParams)
  const [pagingParams, setPagingParams] = useState(initialPagingParams)
  const [operationId, setOperationId] = useState('')

  const {data: OperationTypesNames, isLoading} = useFetchOperationTypesNamesQuery()

  const operationsToSelect = useMemo(() =>
    OperationTypesNames
      ? OperationTypesNames.map(
        (name) => ({
          label: name,
          value: name,
        }))
      : []
  , [OperationTypesNames])

  const handleReset = () => {
    setFilterParams(initialFilterParams)
  }

  const handleSetFilterParams = (params: OperationsFilterParams) => {
    setFilterParams((p) => ({ ...p, ...params }))
    setPagingParams((p) => ({ ...p, pageIndex: 0 }))
  }

  const handleSetPagingParams = (params: Partial<OperationsPagingParams>) => {
    setPagingParams((p) => ({ ...p, ...params }))
  }

  useEffect(() => {
    setCountOfFilters(0)
  }, [])

  if (isLoading) return <Preloader withLeftMargin/>

  if (isPhone) return (
    <>
      <OperationCards
        filterParams={filterParams}
        pagingParams={pagingParams}
        setPagingParams={handleSetPagingParams}
        operationId={operationId}
        setOperationId={setOperationId}
      />
      {filterModal && (
        <FilterModal<OperationsFilterParams>
          params={filterParams}
          schema={filterOperationsSchema}
          clearParams={handleReset}
          setParams={handleSetFilterParams}
          selectOptions={operationsToSelect}
          selectLabel='Operations'
          selectName='type'
          statusOptions={statusToSelect}
          onClose={() => setFilterModal(false)}
          setCountOfFilters={setCountOfFilters}
        />
      )}
    </>
  )

  return (
    <>
      <Filter<OperationsFilterParams>
        params={filterParams}
        schema={filterOperationsSchema}
        clearParams={handleReset}
        setParams={handleSetFilterParams}
        selectOptions={operationsToSelect}
        selectLabel='Operations'
        selectName='type'
        statusOptions={statusToSelect}
      />
      <OperationTable
        filterParams={filterParams}
        pagingParams={pagingParams}
        setPagingParams={handleSetPagingParams}
        operationId={operationId}
        setOperationId={setOperationId}
      />
    </>
  )
}

export default TabHistory
