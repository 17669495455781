import { SxProps, Theme } from '@mui/material'


const profile: SxProps<Theme> = {
  width: '100%',
  padding: '24px 8px',
  background: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-start',
  gap: '16px',
}

const infoItem = (active: boolean): SxProps => ({
  padding: '16px 8px 16px 16px',
  display: 'flex',
  alignItems: 'center',
  bgcolor: active ? '#B388FF99': 'none',
  transition: 'background .2s',
  borderRadius: '10px',
  ':hover': {
    bgcolor: active ? '#B388FF99' : '#B388FF33',
  },
})

const typography: SxProps = {
  paddingLeft: '12px',
  fontWeight: 500,
  fontSize: '16px',
  color: 'text.contrast',
  lineHeight: 1.3,
}

const icon: SxProps = {
  color: 'text.contrast'
}

const Styles = {
  profile,
  infoItem,
  typography,
  icon
}

export default Styles



