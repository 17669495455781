import { Box, SxProps, Theme } from '@mui/material'
import { FC, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  index: number
  value: number
}

const tabPanel: SxProps<Theme> = {
  // backgroundColor: (theme) => theme.palette.gray.light,
}

const TabPanel: FC<Props> = ({ value, index, children, ...rest }) => {
  return (
    <Box role='tabpanel' hidden={value !== index} {...rest} sx={tabPanel}>
      {value === index && children}
    </Box>
  )
}

export default TabPanel
