import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import EmptyList from 'components/EmptyList'
import LinearLoader from 'components/LinearLoader'
import ScrollableBox from 'components/ScrollableBox'
import TableBodyRow from './TableBodyRow'
import TableHead from './TableHead'
import OperationInfoModal from 'components/OperationInfoModal'

import { useGetAllWalletOperationsQuery } from 'services/operations'

import { useCurrencyByCode } from 'hooks/useCurrencyByCode'
import { useMediaSize } from 'hooks/useMediaSize'

import { OperationsFilterParams, OperationsPagingParams } from 'types/operation'

import s from './styles'


const MIN_ROW_HEIGHT = 58
const FIRST_ROW_PADDING = 0

interface Props {
  filterParams: OperationsFilterParams
  pagingParams: OperationsPagingParams
  setPagingParams: (params: Partial<OperationsPagingParams>) => void
  operationId: string
  setOperationId: Dispatch<SetStateAction<string>>
}

const OperationTable = ({
  filterParams,
  pagingParams,
  setPagingParams,
  operationId,
  setOperationId
}: Props) => {

  const [searchParams] = useSearchParams()
  const currencyCode = searchParams.get('currency') || ''
  const wallet = searchParams.get('wallet')

  const { inputSize } = useMediaSize()

  const { data, isFetching } = useGetAllWalletOperationsQuery(
    { paging: pagingParams, filter: filterParams, id: wallet ?? '' },
    { refetchOnMountOrArgChange: true }
  )
  const { currency, isLoadingCurrency } = useCurrencyByCode(
    currencyCode
  )

  const handlePageChange = (
    event: ChangeEvent<unknown>,
    value: number
  ) => {
    setPagingParams({ pageIndex: value - 1 })
  }

  return (
    <Paper elevation={0} sx={s.wrapper}>
      <ScrollableBox sx={{overflow: 'hidden'}}>
        <Table>
          <TableHead
            pagingParams={pagingParams}
            setPagingParams={setPagingParams}
          />
          <TableBody
            sx={{
              display: 'block',
              minHeight: `${
                MIN_ROW_HEIGHT * pagingParams.pageSize + FIRST_ROW_PADDING
              }px`,
            }}
          >
            {isFetching || isLoadingCurrency ? (
              <TableRow sx={s.loaderRow}>
                <TableCell sx={s.loaderCell}>
                  <LinearLoader />
                </TableCell>
              </TableRow>
            ) : !data?.content.length ? (
              <EmptyList />
            ) : (
              data?.content.map((item) => (
                <TableBodyRow
                  key={item.id}
                  item={item}
                  currency={currency}
                  setOperationId={setOperationId}
                />
              ))
            )}
          </TableBody>
        </Table>
      </ScrollableBox>
      <Box sx={s.paginationWrapper}>
        {data?.totalPages ? (
          <Pagination
            page={pagingParams.pageIndex + 1}
            onChange={handlePageChange}
            size={inputSize}
            count={data?.totalPages}
            color='primary'
            shape='rounded'
            showFirstButton
            showLastButton
          />
        ): null}
      </Box>
      {operationId && <OperationInfoModal id={operationId} onClose={() => setOperationId('')}/>}
    </Paper>
  )
}

export default OperationTable