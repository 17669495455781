import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import WalletDetail from '../WalletDetail'
import OperationButtons from '../OperationButtons'
import WalletSettings from '../WalletSettings'

import { theme } from 'theme'
import { useFetchAllWalletsQuery } from 'services/wallets'
import { Wallet } from 'types/wallet'
import { Currency } from 'types/currency'


const StyledSwiper = styled(Swiper)`
  & .swiper-pagination-bullet {
    background-color: #FFFFFF;
    border-color: ${theme.palette.primary.main};
    width: 10px;
    height: 10px;
  },
  & .swiper-pagination-bullet-active {
    background-color: ${theme.palette.primary.main};
  },
  & .swiper-pagination {
    margin-left: 16px;
  },
`

interface Props {
  currentWallet:Wallet
  currency: Currency | undefined
}

const WalletCarousel:FC<Props> = ({currentWallet, currency}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const wallet = searchParams.get('wallet')
  const currentCurrency = searchParams.get('currency')
  const excludeEmpty = searchParams.get('excludeEmpty') === 'true'

  const { data: wallets } = useFetchAllWalletsQuery({currency: currentCurrency ?? 'USD', excludeEmpty}, {refetchOnMountOrArgChange:true})

  const initialSlide =
    wallets?.findIndex((w) => w.id === wallet) ?? 0

  const handleSlideChange = ({ activeIndex }: { activeIndex: number }) => {
    if (wallets?.length) {
      setSearchParams({ ...Object.fromEntries(searchParams), wallet: wallets[activeIndex].id })
    }
  }

  return (
    currentWallet &&
      <Box sx={{p: {xs: 2, sm: 3}}} >
        <Box sx={{display: 'flex', cursor: (wallets && wallets?.length > 1) ? 'pointer' : ''}}>
          <StyledSwiper
            initialSlide={initialSlide !== -1 ? initialSlide : 0}
            defaultValue={0}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            slidesPerView={1}
            keyboard={true}
            onSlideChange={handleSlideChange}
          >
            {wallets?.map((wallet) => (
              <SwiperSlide style={{ paddingBottom: '20px' }} key={wallet.id}>
                <WalletDetail wallet={wallet} currency={currency}/>
              </SwiperSlide>
            ))}

          </StyledSwiper>
          <WalletSettings wallet={currentWallet}/>
        </Box>
        <OperationButtons />
      </Box>
  )
}

export default WalletCarousel