import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const WalletsPreloader = () => {
  return (
    <Box width='100%' height='100px' display='flex' justifyContent='center' alignItems='center'>
      <CircularProgress sx={{color: 'text.contrast'}}/>
    </Box>
  )
}

export default WalletsPreloader
