import { FC, ReactNode } from 'react'
import { Link, useMatch } from 'react-router-dom'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import { MenuItem } from '@mui/material'


export interface IProfileNavigationItem {
  label: string
  path: string
  icon: ReactNode
}

const MobileProfileNavigationItem: FC<IProfileNavigationItem> = ({label, path, icon}) => {
  const match = useMatch({
    path: path,
    end: true,
  })

  return (
    <MenuItem
      sx={{
        borderRadius: '8px',
        display: 'flex',
        width: '100%',
        color: match ? 'text.contrast' : 'primary.main',
        bgcolor: match ? 'primary.main' : 'none',
        'a': {p: 1},
        ':hover': {bgcolor: match ? 'primary.main' : 'primary.light'}
      }}
      >
      <Link to={path}>
        <Box
          sx={{display: 'flex', gap: 1 }}
        >
          <SvgIcon>{icon}</SvgIcon>
          <Typography fontWeight={500}>{label}</Typography>
        </Box>
      </Link>
    </MenuItem>
  )
}

export default MobileProfileNavigationItem