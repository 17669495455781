import { FC } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'

import Modal from 'components/Modal'
import { ReactComponent as SuccesIcon } from 'assets/svg/Success.svg'


interface Props {
  onClose: () => void
  text?: string
}

const SuccessModal: FC<Props> = ({
  onClose,
  text='Successfully completed'
}) => {

  return (
    <Modal
      open
      onClose={onClose}
      handleClose={onClose}
    >
      <Typography fontWeight={500} maxWidth={380} textAlign='center'>{text}</Typography>
      <Box py={4} textAlign='center'>
        <SuccesIcon/>
      </Box>
      <Button variant='contained' onClick={onClose} sx={{width: '180px', m: '0 auto', display: 'block'}}>OK</Button>
    </Modal>
  )
}

export default SuccessModal