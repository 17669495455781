import { TableCell, TableRow, Typography } from '@mui/material'

import { OperationType } from 'assets/const'

import { formatAmountForTable } from 'utils/intlFormat'
import {getOperationValue, removeUnderscore} from 'utils/operation'

import { Transaction } from 'types/operation'

import s from '../styles'


interface Props {
  item: Transaction
  currencySymbol: string
}

const TransactionTableRow = ({ item, currencySymbol }: Props) => {

  return (
    <TableRow key={item.id} sx={{...s.row, ...s.bodyRow}}>
      <TableCell sx={{justifyContent: 'left'}} align='left'>
        <Typography sx={s.overflowedText}>
          {removeUnderscore(getOperationValue(item.type, OperationType))}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography sx={s.overflowedText}>
          {formatAmountForTable(item.amount, currencySymbol)}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography sx={s.overflowedText}>
          {item.sender}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography sx={s.overflowedText}>
          {item.receiver}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography sx={s.overflowedText}>
          {item.description}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default TransactionTableRow
