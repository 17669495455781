import { createApi } from '@reduxjs/toolkit/dist/query/react'
import {
  TemplateDefaultListResponse,
  TemplateListRequestResponse,
} from 'types/responses'
import { PSDefaultTemplate, PSTemplateRequest } from 'types/template'
import { addSessionId } from 'utils/uuid'
import baseQuery from '../api'

export const templatesApi = createApi({
  reducerPath: 'templatesApi',
  baseQuery,
  tagTypes: ['defaultTemplates', 'savedTemplates'],
  endpoints: (build) => ({
    getWalletDefaultTemplates: build.query<
      PSDefaultTemplate[],
      { walletId: string }
    >({
      query: ({ walletId }) => `wallet/${walletId}/withdraw/templates/default`,
      providesTags: () => ['defaultTemplates'],
      transformResponse: (response: TemplateDefaultListResponse) =>
        response.list,
    }),
    getWalletSavedTemplates: build.query<
      PSTemplateRequest[],
      { walletId: string }
    >({
      query: ({ walletId }) => `wallet/${walletId}/withdraw/templates/saved`,
      providesTags: () => ['savedTemplates'],
      transformResponse: (response: TemplateListRequestResponse) =>
        response.list,
    }),
    getUserDefaultTemplates: build.query<PSDefaultTemplate[], void>({
      query: () => `user/profile/paymentsettings/withdraw/templates/default`,
      providesTags: () => ['defaultTemplates'],
      transformResponse: (response: TemplateDefaultListResponse) =>
        response.list,
    }),
    getUserSavedTemplates: build.query<PSTemplateRequest[], void>({
      query: () => `user/profile/paymentsettings/withdraw/templates/saved`,
      providesTags: () => ['savedTemplates'],
      transformResponse: (response: TemplateListRequestResponse) =>
        response.list,
    }),
    createTemplate: build.mutation<void, PSTemplateRequest>({
      query: (template) => ({
        url: `user/profile/paymentsettings/withdraw/templates/create`,
        method: 'POST',
        body: addSessionId(template),
      }),
    }),
    editTemplate: build.mutation<void, PSTemplateRequest>({
      query: (template) => ({
        url: `user/profile/paymentsettings/withdraw/templates`,
        method: 'POST',
        body: addSessionId(template),
      }),
    }),
    deleteTemplate: build.mutation<void, { templateId: string }>({
      query: ({ templateId }) => ({
        url: `user/profile/paymentsettings/withdraw/templates/${templateId}/delete`,
        method: 'POST',
        body: addSessionId(),
      }),
    }),
  }),
})

export const {
  useGetWalletDefaultTemplatesQuery,
  useGetWalletSavedTemplatesQuery,
  useGetUserDefaultTemplatesQuery,
  useGetUserSavedTemplatesQuery,
  useCreateTemplateMutation,
  useEditTemplateMutation,
  useDeleteTemplateMutation,
} = templatesApi
