import { FC, ReactNode } from 'react'
import { Box, StandardTextFieldProps, SvgIcon, Typography } from '@mui/material'
import Select from 'components/Select'
import { Wallet } from 'types/wallet'
import { formatBalanceWithDecimals } from 'utils/intlFormat'
import { Currency } from 'types/currency'

import { getCurrencyIcon } from 'assets/const'


export interface Option {
  label: string
  value: string
}

interface Props extends StandardTextFieldProps {
  control: any
  name: string
  label: string
  errors: any
  options: Option[]
  wallets: Wallet[]
  selectedWalletId: string
  renderValue?: ReactNode
  currency: Currency
}

const CustomWalletsSelect: FC<Props> = ({
  control,
  name,
  label,
  options,
  wallets,
  selectedWalletId,
  currency,
}) => {
  const selectedWallet = wallets.filter(wallet => wallet.id === selectedWalletId)[0]

  return (
    <Select
      control={control}
      name={name}
      label={label}
      options={options}
      errors={''}
      sx={{
        bgcolor: 'gray.light',
        pl: 0,
        '& .MuiInputBase-root.MuiOutlinedInput-root': {pl : '10px'},
        '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {paddingY: '8px', minHeight: '40px'}
      }}
      InputProps={{
        startAdornment:
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '32px',
              minHeight: '32px',
              border: '1px solid',
              borderColor: 'primary.main',
              borderRadius: '50%',
              color: 'primary.main',
              fontSize: 24
            }}
          >
            {getCurrencyIcon[wallets[0]?.currency] ?
              <SvgIcon
                component={getCurrencyIcon[wallets[0]?.currency]}
                sx={{ color: 'primary.main', transform: 'scale(.8)' }}
                inheritViewBox
              />
              :
              currency.symbol
            }
          </Box>
      }}
      renderValue={
        selectedWallet &&
          <Box ml={1}>
            <Typography fontSize={10} fontWeight={500}>{selectedWallet.name}</Typography>
            <Typography fontSize={14} mt={.5}>{formatBalanceWithDecimals(selectedWallet.balance, currency.maxDecimals)} {currency.symbol}</Typography>
          </Box>
      }
    />
  )
}

export default CustomWalletsSelect