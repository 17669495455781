import {
  Button,
  CircularProgress,
  FormHelperText,
} from '@mui/material'
import Box from '@mui/material/Box'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import CustomCheckbox from 'components/Checkbox'
import Modal from 'components/Modal'
import TextInput from 'components/TextInput'

import { useUpdateWalletMutation } from 'services/wallets'

import { ResponseError } from 'assets/const'
import { Wallet } from 'types/wallet'


type EditFormValues = {
  name: string
  isDefault: boolean
}

interface Props {
  handleClose: () => void
  wallet: Wallet
}

const EditWallet: FC<Props> = ({ handleClose, wallet }) => {
  const [updateWallet, { isLoading }] = useUpdateWalletMutation()

  const {
    setValue,
    setError,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditFormValues>({
    mode: 'onBlur',
    defaultValues: {
      name: wallet.name,
      isDefault: wallet.isDefault,
    },
  })

  const onSubmit: SubmitHandler<EditFormValues> = async (data) => {
    await updateWallet({ ...data, id: wallet.id })
      .unwrap()
      .then(() => {
        handleClose()
      })
      .catch((err) => {
        if (err.data.code === ResponseError.DefaultWallet.code) {
          setError('isDefault', {
            type: 'validate',
            message: ResponseError.DefaultWallet.message,
          })
          setValue('isDefault', wallet.isDefault)
        }
      })
  }

  return (
    <Modal handleClose={handleClose} open sx={{ overflow: 'hidden' }} title='Edit wallet'>
      {isLoading && (
        <Box
          sx={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        component={'form'}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ minWidth: { sm: '381px' }, display: 'flex', flexDirection: 'column' }}
      >
        <TextInput
          control={control}
          name='name'
          label='Wallet name'
          errors={errors}
        />

          <Box mt='16px' alignItems='end'>
          {!wallet.isDefault ? (
            <>
              <CustomCheckbox
                label='Set as default'
                control={control}
                name='isDefault'
              />
              {errors.isDefault?.ref && (
                <FormHelperText error>{errors.isDefault.message}</FormHelperText>
              )}
            </>
          ) : (
            <CustomCheckbox
                label='Default wallet'
                control={control}
                disabled
                name='isDefault'
              />
          )}
          </Box>

        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={isLoading}
          sx={{mt: 3, width: '180px', alignSelf: 'center'}}
        >
          Save
        </Button>
      </Box>
    </Modal>
  )
}

export default EditWallet