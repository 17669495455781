import * as yup from 'yup'

export const activationSchema = yup.object().shape({
  code: yup.string().when('$isMethodAlreadySet', {
    is: (isMethodAlreadySet: boolean) => {
      return isMethodAlreadySet
    },
    then: yup.string().required('Please enter a valid code'),
  }),
  type: yup.string().required(),
})
