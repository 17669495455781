import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import ScrollableBox from 'components/ScrollableBox'
import TemplateProfileCard from '../TemplateProfileCard'
import { toastError } from '../../../utils/toast'
import EditDetails from '../EditDetails'
import ConfirmModal from 'components/ConfirmModal'

import { AppRoute, getPaymentIconByCode } from 'assets/const'
import {
  useDeleteTemplateMutation,
  useEditTemplateMutation,
} from 'services/templates'
import { PSDefaultTemplate, PSTemplateRequest } from 'types/template'


interface Props {
  savedTemplates: PSTemplateRequest[]
  defaultTemplates: PSDefaultTemplate[]
  isSavedUpdating: boolean
  setIsSavedUpdating: (isSavedUpdating: boolean) => void
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
}

const SavedTemplate = ({
  savedTemplates,
  defaultTemplates,
  isSavedUpdating,
  isModalOpen,
  setIsModalOpen,
  setIsSavedUpdating,
}: Props) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const templateId = searchParams.get('templateId')
  const [activeTemplate, setActiveTemplate] = useState<PSDefaultTemplate | null>(null)
  const [isConfirmModalOpen, setisConfirmModalOpen] = useState(false)
  const [editTemplate] = useEditTemplateMutation()
  const [deleteTemplate, { isLoading: isDeleting, originalArgs }] =
    useDeleteTemplateMutation()

  const handleEdit = ({ id, code, name, ...values }: PSTemplateRequest) => {
    const foundTemplate = defaultTemplates.find((t) => t.code === code)
    if (!foundTemplate) {
      toastError('Template not found')
      return
    }
    const template: PSDefaultTemplate = {
      id,
      name,
      code,
      psCode: foundTemplate.psCode,
      paymentFormDefinition: foundTemplate.paymentFormDefinition,
      values: values?.paymentFormDefinition,
    }
    setActiveTemplate(template)
    setIsModalOpen(true)
  }

  const handleDelete = (id: string) => {
    setSearchParams(prevParams => {
      const params = new URLSearchParams(prevParams)
        params.set('templateId', id)
        return params
      })
    setisConfirmModalOpen(true)
  }

  const confirmDelete = () => {
    setIsSavedUpdating(true)
    if (templateId) {
      deleteTemplate({ templateId }).then(() => {
        navigate(`/${AppRoute.Profile}/${AppRoute.Templates}`)
        setisConfirmModalOpen(false)
      })
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleSaveTemplate = (template: PSTemplateRequest) => {
    setIsSavedUpdating(true)
    editTemplate(template)
  }

  return (
    <>
      <Box mt={4}>
        <Typography sx={{ mb: 3, fontWeight: 500 }}>
          Manage saved template:
        </Typography>
        <ScrollableBox maxHeight={220} width='100%'>
          {savedTemplates.map((template, index) => (
            <TemplateProfileCard
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              isDeleting={
                (isSavedUpdating || isDeleting) &&
                originalArgs?.templateId === template.id
              }
              template={template}
              key={template.id}
              icon={
                getPaymentIconByCode[
                  template.code as keyof typeof getPaymentIconByCode
                ]
              }
              index={index}
            />
          ))}
        </ScrollableBox>
      </Box>
      {isModalOpen && activeTemplate && (
        <EditDetails
          template={activeTemplate}
          isFetching={isSavedUpdating}
          handleClose={handleModalClose}
          handleSave={handleSaveTemplate}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          variant='confirm'
          onConfirm={confirmDelete}
          loading={isDeleting}
          onClose={() => {
            setisConfirmModalOpen(false)
              navigate(`/${AppRoute.Profile}/${AppRoute.Templates}`)
          }}
          text='Are you sure you want to delete the withdraw template?'
        />
      )}
    </>
  )
}

export default SavedTemplate