import { FC } from 'react'
import { Badge, Box } from '@mui/material'

import {ReactComponent as FilterIcon} from 'assets/svg/FilterSettings.svg'


interface Props {
  setFilterModal: () => void
  countOfFilters: number
}

const MobileFilterButton: FC<Props> = ({countOfFilters, setFilterModal}) => {

  return (
    <Badge
      showZero={false}
      badgeContent={countOfFilters}
      color='primary'
      sx={{
        '& .MuiBadge-badge': {
          marginleft: '50px',
          top: 5,
          right: 5,
          border: '3px solid',
          borderColor: 'gray.light',
          height: '27px',
          width: '27px',
          borderRadius: '50%'
        }
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        minWidth={50}
        height={50}
        border='1px solid'
        borderColor='primary.main'
        borderRadius={1}
        ml={1}
        onClick={setFilterModal}
      >
        <FilterIcon/>
      </Box>
    </Badge>
  )
}

export default MobileFilterButton
