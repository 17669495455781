import { FC } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Stack } from '@mui/material'

import { AppRoute } from 'assets/const'

import { ReactComponent as TopupIcon } from 'assets/svg/buttons/Topup.svg'
import { ReactComponent as TransferIcon } from 'assets/svg/buttons/Transfer.svg'
import { ReactComponent as WithdrawIcon } from 'assets/svg/buttons/Withdraw.svg'

const OperationButtons: FC = () => {
  const [searchParams] = useSearchParams()

  const buttons = [
    {text: 'Top up', path: `${AppRoute.Refill}`, icon: <TopupIcon/>},
    {text: 'Transfer', path: `${AppRoute.Transfer}`, icon: <TransferIcon/>},
    {text: 'Withdraw', path: `${AppRoute.Withdraw}`, icon: <WithdrawIcon/>},
  ]

  return (
    <Stack
      direction='row'
      sx={{
        width: {xs: '100%', lg: 'auto'},
        mt: {xs: 2, sm: 4},
        justifyContent: 'space-between',
        gap: {xs: 2, sm: 3}
      }}
    >
      {buttons.map(button =>
        <Button
          key={button.text}
          variant='outlined'
          endIcon={button.icon}
          component={Link}
          to={`${button.path}?${searchParams}`}
          sx={{
            minWidth: {xs: '29%', lg: '152px'},
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'row'},
            alignItems: 'center',
            padding: '4px 4px',
            maxHeight: '56px',
            'span': {ml: {xs: 0, sm: '8px'}}
          }}
        >
          {button.text}
        </Button>
      )}
    </Stack>
  )
}

export default OperationButtons