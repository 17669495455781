import { CssBaseline } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import NotFound from 'components/NotFound'
import MainLayout from 'layouts/MainLayout'
import More from 'pages/more'
import Dashboard from 'pages/dashboard'
import Faq from 'pages/faq'
import Profile from 'pages/profile'
import Refill from 'pages/refill'
import Terms from 'pages/terms'
import AuthenticationSettings from 'pages/two-factor-authentication'
import UserInfo from 'pages/user-info'
import Withdraw from 'pages/withdraw'
import WithdrawTemplates from 'pages/withdraw-templates'
import Transfer from 'pages/transfer'

import { AppRoute } from 'assets/const'

import 'App.scss'

const App = () => {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route element={<MainLayout/>}>
            <Route path={AppRoute.Profile} element={<Profile />}>
              <Route index element={<UserInfo />} />
              <Route path={AppRoute.Templates} element={<WithdrawTemplates />}/>
              <Route
                path={AppRoute.AuthSettings}
                element={<AuthenticationSettings />}
              />
              {/* <Route path={AppRoute.IpWhitelist} element={<IpWhitelist />} /> */}
            </Route>
            {/* <Route
              path={`${AppRoute.Operation}/:id`}
              element={<OperationInfo />}
            /> */}
            <Route
              path={`${AppRoute.Withdraw}`}
              element={<Withdraw />}
            />
            <Route
              path={`${AppRoute.Transfer}`}
              element={<Transfer />}
            />
            <Route
              path={`${AppRoute.Refill}`}
              element={<Refill />}
            />
            <Route path={AppRoute.Home} element={<Dashboard />} />
              {/* <Route
                path={`${AppRoute.Operation}/:id`}
                element={<OperationInfo />}
              /> */}
            <Route path={AppRoute.Payments} element={<Terms />} />
            <Route path={AppRoute.Faq} element={<Faq />} />
            <Route path={AppRoute.More} element={<More />} />
            <Route path={AppRoute.Terms} element={<Terms />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App