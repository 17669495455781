import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Transaction } from 'types/operation'

import TransactionTableRow from './TransactionTableRow'
import s from './styles'

interface Props {
  items: Transaction[]
  currencySymbol: string
}

interface HeaderCell {
  name: string,
  align: 'left' | 'center',
}

const headerCell: HeaderCell[] = [
  {name: 'Operation', align: 'left'},
  {name: 'Amount', align: 'center'},
  {name: 'Sender', align: 'center'},
  {name: 'Receiver', align: 'center'},
  {name: 'Description', align: 'center'},
]

const TransactionsTable = ({ items, currencySymbol }: Props) => {
  return (
    <Table
      sx={{
        minWidth: '640px',
      }}
    >
      <TableHead>
        <TableRow sx={{...s.row, ...s.headerRow}}>
          {headerCell.map(header =>
            <TableCell key={header.name} sx={s.headerCell} align={header.align}>
              {header.name}
            </TableCell>
          )}
          {/* <TableCell sx={s.headerCell} align='left'>
            Operation
          </TableCell>
          <TableCell sx={s.headerCell} align='center'>
            Amount
          </TableCell>
          <TableCell sx={s.headerCell}  align='center'>
            Sender
          </TableCell>
          <TableCell sx={s.headerCell} align='center'>
            Receiver
          </TableCell>
          <TableCell sx={s.headerCell} align='center'>
            Description
          </TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TransactionTableRow item={item} key={item.id} currencySymbol={currencySymbol}/>
        ))}
      </TableBody>
    </Table>
  )
}

export default TransactionsTable
