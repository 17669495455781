import { FC, SyntheticEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Tabs } from '@mui/material'

import CustomTab from './CustomTab'

import { getDefaultName } from 'utils/text'
import { Wallet } from 'types/wallet'
import { Currency } from 'types/currency'

import s from './styles'


interface Props {
  wallets: Wallet[]
  currencyInfo: Currency | undefined
}

const WalletTabs: FC<Props> = ({wallets, currencyInfo}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const wallet = searchParams.get('wallet')

  const handleChangeTab = (
    evt: SyntheticEvent<Element, Event>,
    newWalletId: string
  ) => {
    if (wallet === newWalletId) return
    const newWallet = wallets?.find((w) => w.id === newWalletId)?.id || ''
    setSearchParams({ ...Object.fromEntries(searchParams), wallet: newWallet})
  }

  return (
    <Tabs
      orientation='vertical'
      variant='scrollable'
      sx={s.tabs}
      TabScrollButtonProps={{
        sx: {
          height: '20px',
          color: 'text.contrast'
        },
      }}
      TabIndicatorProps={{
        hidden: true,
      }}
      value={wallet}
      onChange={handleChangeTab}
    >
      {wallets?.map((wallet) => (
        <CustomTab
          key={wallet.id}
          walletName={wallet.name || getDefaultName(wallet.currency)}
          value={wallet.id}
          balance={wallet.balance}
          reserved={wallet.reserved}
          currency={currencyInfo}
          role='tab'
          margin='0 0 4px 0'
        />
      ))}
    </Tabs>
  )
}

export default WalletTabs