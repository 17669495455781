import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { ReactComponent as BackArrow } from 'assets/svg/BackArrow.svg'
import { theme } from 'theme'


interface Props {
  text?: string
  link?: string
}

const BackButton: FC<Props> = ({text='Back to wallet', link}) => {
  const navigate= useNavigate()
  const {search} = useLocation()
  const onClick = () => {
    link ? navigate(link) :
      search ? navigate(`/${search}`) : navigate('/')
  }

  return (
      <Box onClick={onClick} sx={{mb: 3, display: 'inline-flex', alignItems: 'center', cursor:'pointer'}}>
        <BackArrow color={theme.palette.primary.main}/>
        <Typography fontSize={14} pl={1} sx={{':hover': {color: 'primary.main'}}}>
          {text}
        </Typography>
      </Box>
  )
}

export default BackButton