import TableCell from '@mui/material/TableCell'
import TableHeader from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import { WithdrawField } from 'assets/const'
import { WithdrawPagingParams } from 'types/operation'

import s from '../../OperationTable/styles'


interface HeadCell {
  disablePadding: boolean
  id: keyof typeof WithdrawField
  label: string
  align: 'left' | 'right' | 'center'
  isSortable?: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'updated',
    align: 'center',
    disablePadding: true,
    label: 'Updated',
    isSortable: false,
  },
  {
    id: 'method',
    align: 'center',
    disablePadding: true,
    label: 'Payment Method',
    isSortable: false,
  },
  {
    id: 'amount',
    align: 'center',
    disablePadding: true,
    label: 'Amount',
    isSortable: false,
  },
  {
    id: 'commission',
    align: 'center',
    disablePadding: true,
    label: 'Commission',
    isSortable: false,
  },
  {
    id: 'status',
    align: 'center',
    disablePadding: true,
    label: 'Status',
    isSortable: false,
  },
  {
    id: 'comment',
    align: 'center',
    disablePadding: true,
    label: 'Comment',
    isSortable: false,
  },
]

interface Props {
  setPagingParams: (params: Partial<WithdrawPagingParams>) => void
  pagingParams: WithdrawPagingParams
}

const TableHead=({ setPagingParams, pagingParams }: Props)=> {
  const handleSort = (newSortField: keyof typeof WithdrawField) => {
    const isAsc =
      pagingParams.sortField === newSortField &&
      pagingParams.sortDirection === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'

    setPagingParams({ sortDirection: toggledOrder, sortField: newSortField })
  }

  return (
    <TableHeader>
      <TableRow sx={{ ...s.row, ...s.withdrawRowGrid, ...s.shadowRow  }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              pagingParams.sortField === headCell.id
                ? pagingParams.sortDirection
                : false
            }
          >
            {headCell.isSortable ? (
              <TableSortLabel
                sx={{ ml: headCell.align === 'center' ? '20px' : 0 }}
                active={pagingParams.sortField === headCell.id}
                direction={
                  pagingParams.sortField === headCell.id
                    ? pagingParams.sortDirection
                    : 'asc'
                }
                title={`sorting by ${headCell.label}`}
                onClick={() => {
                  handleSort(headCell.id)
                }}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  )
}

export default TableHead