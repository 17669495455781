import { SxProps } from '@mui/material'


const modal: SxProps = {
  padding: {xs: 2, sm: 3},
  position: 'relative',
  boxSizing: 'border-box',
  maxWidth: '920px',
  width: '100%',
}

const title = ( pt: number): SxProps => ({
  fontSize: 18,
  fontWeight: 600,
  pt: pt,
  pb: 3,
  textAlign: 'center'
})

const closeButton: SxProps = {
  width: '20px',
  height: '20px',
  color: 'gray.dark',
  position: 'absolute',
  right: '0px',
  top: '0px',
  margin: '23px 23px 0',
  zIndex: 100,
  '&:hover': {
    cursor: 'pointer'
  }
}

const Styles = {
  modal,
  title,
  closeButton,
}

export default Styles