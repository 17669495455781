import { useSearchParams } from 'react-router-dom'
import {
  useGetWalletDefaultTemplatesQuery,
  useGetWalletSavedTemplatesQuery,
} from 'services/templates'
import { useFetchUserProfileQuery } from 'services/user'
import { useGetWalletQuery } from 'services/wallets'
import { useCurrencyByCode } from './useCurrencyByCode'

export const useWithdrawData = () => {
  const [searchParams] = useSearchParams()
  const walletId = searchParams.get('wallet')

  const { data: user, isLoading: isLoadingUser } = useFetchUserProfileQuery()
  const { data: wallet, isLoading: isWalletLoading } = useGetWalletQuery(
    walletId ?? '',
    {
      skip: !walletId,
      refetchOnMountOrArgChange: true,
    }
  )
  const { data: defaultTemplates, isLoading: isDefaultTemplatesLoading } =
    useGetWalletDefaultTemplatesQuery(
      { walletId: walletId || '' },
      {
        skip: !walletId,
        refetchOnMountOrArgChange: true,
      }
    )

  const {
    data: savedTemplates,
    isLoading: isSavedTemplatesLoading,
    refetch: refetchSavedTemplates,
  } = useGetWalletSavedTemplatesQuery(
    { walletId: walletId || '' },
    {
      skip: !walletId,
      refetchOnMountOrArgChange: true,
    }
  )

  const { currency, isLoadingCurrency } = useCurrencyByCode(wallet?.currency)

  const isLoading =
    isSavedTemplatesLoading ||
    isDefaultTemplatesLoading ||
    isWalletLoading ||
    isLoadingCurrency ||
    isLoadingUser

  return {
    user,
    currency,
    defaultTemplates,
    savedTemplates,
    wallet,
    refetchSavedTemplates,
    isLoading,
  }
}