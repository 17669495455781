import { Box, Typography } from '@mui/material'
import { Transaction } from 'types/operation'
import { formatAmountForTable } from 'utils/intlFormat'

import s from '../styles'


interface Props {
  items: Transaction[]
  currencySymbol: string
}

const TransactionsCards = ({ items, currencySymbol }: Props) => {

  return (
    <Box sx={s.transactionsWrapper}>
      {items.map(transaction =>
        <Box sx={s.wrapper} key={transaction.id}>
          <Box sx={s.transactionData}>
            <Typography sx={s.transactionLabel}>Operation:</Typography>
            <Typography sx={s.value}>{transaction.type}</Typography>
          </Box>
          <Box sx={s.transactionData}>
            <Typography sx={s.transactionLabel}>Amount:</Typography>
            <Typography sx={s.value}>
              {formatAmountForTable(transaction.amount, currencySymbol)}
            </Typography>
          </Box>
          <Box sx={s.transactionData}>
            <Typography sx={s.transactionLabel}>Sender:</Typography>
            <Typography sx={s.value}>{transaction.sender}</Typography>
          </Box>
          <Box sx={s.transactionData}>
            <Typography sx={s.transactionLabel}>Receiver:</Typography>
            <Typography sx={s.value}>{transaction.receiver}</Typography>
          </Box>
          <Box sx={s.transactionData}>
            <Typography sx={s.transactionLabel}>Description:</Typography>
            <Typography sx={s.value}>{transaction.description}</Typography>
          </Box>
        </Box>
      )}

    </Box>
  )
}

export default TransactionsCards
