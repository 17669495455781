import { Box, Typography } from '@mui/material'
import { APP_VERSION } from 'assets/version'
import s from './styles'


const Footer = () => {

  return (
    <Box sx={s.footerWrapper}>
      <Typography sx={s.footerContent}>
        <span>Copyright ©{new Date().getFullYear()} </span>
        CleverWallet LLC. All rights reserved.Version:
        {APP_VERSION}
      </Typography>
    </Box>
  )
}

export default Footer
