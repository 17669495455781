import { SxProps, Theme } from '@mui/material'

// import FrameMd from 'assets/images/FrameMd.png'
// import FrameSm from 'assets/images/FrameSm.png'
// import FrameXs from 'assets/images/FrameXs.png'

const wrapper: SxProps<Theme> = {
  // position: 'relative',
  padding: '24px',
  bgcolor: 'white',
  color: (theme) => theme.palette.text.primary,
  // '&::before': {
  //   background: (theme) => ({
  //     md: `url(${FrameMd}) no-repeat right bottom, ${theme.palette.primary.gradient}`,
  //     sm: `url(${FrameSm}) no-repeat right bottom 47px, ${theme.palette.primary.gradient}`,
  //     xs: `url(${FrameXs}) no-repeat right bottom, ${theme.palette.primary.gradient}`,
  //   }),
  //   content: '""',
  //   position: 'absolute',
  //   height: '100%',
  //   width: '100%',
  //   top: 0,
  // },
}

const tableWrapper: SxProps = {
  display: 'flex',
  gap: '10px',
  marginTop: { xs: '16px', sm: '24px' },
}

const adaptiveContainer: SxProps = {
  margin: { lg: '0 auto 0', md: '0 0 0 auto' },
  maxWidth: { md: '1100px', lg: '1180px' },
}

const addWalletWrapper: SxProps = {
  width: { xs: '80%', sm: '60%' },
  m: { xs: '12px auto 24px', sm: '24px auto 34px' },
  textAlign: 'center',
}

const addWalletButton: SxProps = {
  textTransform: 'none',
  fontSize: { xs: '20px', sm: '24px' },
}

const Styles = {
  wrapper,
  tableWrapper,
  adaptiveContainer,
  addWalletWrapper,
  addWalletButton,
}

export default Styles
