import { SxProps, Theme } from '@mui/material'

const wrapper: SxProps = {
  padding: '24px',
  mt: '16px',
}

const row: SxProps = {
  display: 'grid !important',
  // width: '100%',
  alignItems: 'center',
}

const operationRowGrid: SxProps = {
  gridTemplateColumns: '12% 25% 21% 21% 21%',
}

const operationRowHover: SxProps = {
  cursor: 'pointer',
  ':hover': {
    bgcolor: 'primary.light',
  },
}

const withdrawRowGrid: SxProps = {
  gridTemplateColumns: '12% 20% 15% 15% 21% 17%',
}

const shadowRow: SxProps = {
  boxShadow: '-1px 3px 5px 1px #A4A4A440',
  boxSizing: 'border-box',
  margin: '2px 2px 24px'
}

const bodyRow: SxProps<Theme> = {
  minHeight: '58px',
  '&:nth-of-type(odd)': {
    backgroundColor: (theme) => theme.palette.gray.light,
  },
}

const paginationWrapper: SxProps = {
  mt: '40px',
  width: 'max-content',
  minHeight: '32px',
  mr: { xs: 'auto', lg: '30px' },
  '& ul': {
    width: { xs: '320px', sm: 'auto' },
    justifyContent: 'space-between',
  },
}

const loaderCell: SxProps = {
  width: '100%',
  display: 'block',
  padding: 0,
  border: 0,
}

const loaderRow: SxProps = { width: '100%', display: 'block' }

const Styles = {
  wrapper,
  row,
  shadowRow,
  operationRowGrid,
  operationRowHover,
  withdrawRowGrid,
  bodyRow,
  paginationWrapper,
  loaderCell,
  loaderRow,
}

export default Styles
