import {
  Avatar,
  Box,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { Wallet } from 'types/wallet'
import { getDefaultName } from 'utils/text'
import { formatBalanceWithDecimals } from 'utils/intlFormat'
import { Currency } from 'types/currency'
import { getCurrencyIcon } from 'assets/const'

import s from '../styles'


interface Props {
  wallet: Wallet
  currency: Currency | undefined
}

const WalletDetail = ({ wallet, currency }: Props) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={s.infoContainer} alignItems={isMobile ? 'start' : 'center'}>
      <Avatar sx={s.avatar}>
        {getCurrencyIcon[wallet.currency] ?
          <SvgIcon
            component={getCurrencyIcon[wallet.currency] || currency?.symbol}
            sx={{ color: 'primary.main', transform: { xs: 'scale(2.1)', sm: 'scale(2.8)' } }}
            inheritViewBox
          />
          :
          currency?.symbol
        }
      </Avatar>

      <Box sx={s.balanceContainer}>
        <Typography sx={s.walletName}>
          {wallet.name || getDefaultName(wallet.currency)}
        </Typography>
        <Typography sx={s.mainBalance}>
          {formatBalanceWithDecimals(wallet.balance, currency?.maxDecimals)}
        </Typography>
        <Typography sx={s.reservedText}>
          Reserved: <span>{formatBalanceWithDecimals(wallet.reserved, currency?.maxDecimals)}</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default WalletDetail
