import { SxProps } from '@mui/material'
import { theme } from 'theme'

export const footerWrapper: SxProps = {
  background: 'rgba(255, 255, 255, 0.94)',
  backdropFilter: 'blur(7px)',
  color: 'var(--primary)',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100vw',
  height: '60px',
  px: {xs: '16px', sm: '20px', md: '24px'},
}

export const footerContent: SxProps = {
  display: {xs: 'flex', sm: 'inline'},
  justifyContent: 'space-between',
  textAlign: 'end',
  flex: '1',
  maxWidth: {xs: '100%', sm: 'max-content'},
  padding: 'auto 0',
  fontSize: '12px',
  fontWeight: '400',
  '& span:first-of-type': {
    display: {xs: 'block', sm: 'inline'},
    color: theme.palette.gray.main,
    whiteSpace: 'nowrap'
  }
}


const Styles = {
  footerWrapper,
  footerContent,
}

export default Styles