import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';

import WithdrawCard from 'components/WithdrawCards/WithdrawCard'
import MobileEmptyListForOperations from 'components/MobileEmptyListForOperations';
import PreloaderForInfiniteScroll from 'components/PreloaderForInfiniteScroll';
import WithdrawInfoModal from 'components/WithdrawInfoModal';

import { useGetWithdrawalsByIdQuery } from '../../services/operations'
import { useCurrencyByCode } from 'hooks/useCurrencyByCode'
import { Withdraw, WithdrawFilterParams, WithdrawPagingParams } from 'types/operation'


interface Props {
  filterParams: WithdrawFilterParams
  pagingParams: WithdrawPagingParams
  setPagingParams: (params: Partial<WithdrawPagingParams>) => void
  setWithdraw: Dispatch<SetStateAction<null | Withdraw>>
  withdraw: Withdraw | null
}

const WithdrawCards: FC<Props> = ({
  filterParams,
  pagingParams,
  setPagingParams,
  withdraw,
  setWithdraw
}) => {
  const [withdraws, setWithdraws] = useState<Withdraw[]>([]);
  const [searchParams] = useSearchParams()
  const walletId = searchParams.get('wallet')
  const currentCurrency = searchParams.get('currency') || ''

  const { data, isLoading } = useGetWithdrawalsByIdQuery(
    {
      page: pagingParams.pageIndex,
      size: pagingParams.pageSize,
      filter: filterParams,
        walletId: walletId
    },
    { refetchOnMountOrArgChange: true }
  )
  const { currency, isLoadingCurrency } = useCurrencyByCode(
    currentCurrency
  )

  useEffect(() => {
    setWithdraws([])
  }, [filterParams, walletId])

  useEffect(() => {
    data && setWithdraws((prev) => ([...prev, ...data.content]))
  }, [data, isLoading])

  const fetchMoreData = () => {
    setPagingParams({pageIndex: pagingParams.pageIndex + 1})
  };

  if (isLoading || isLoadingCurrency) return  <PreloaderForInfiniteScroll />

  return (
    <>
      {data && data?.content.length ?
        <InfiniteScroll
          dataLength={withdraws.length}
          next={fetchMoreData}
          hasMore={withdraws.length < data.totalElements}
          loader={<PreloaderForInfiniteScroll/>}
          endMessage={<Typography textAlign='center'>No more operations</Typography>}
        >
          {withdraws.map((withdraw, index) =>
            <WithdrawCard key={withdraw.id+index} withdraw={withdraw} currencySymbol={currency?.symbol} setWithdraw={setWithdraw}/>
          )}
        </InfiniteScroll>
        :
        <MobileEmptyListForOperations/>
      }
      {withdraw && <WithdrawInfoModal withdraw={withdraw} onClose={() => setWithdraw(null)}/>}
    </>
  )
}

export default WithdrawCards