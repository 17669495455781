import { Box, Container, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Preloader from 'components/Preloader'
import StripeDialog from 'components/StripeDialog'
import BackButton from 'components/BackButton'
import Account from './Account'
import Details from './Details'
import SuccessModal from 'components/SuccessModal'
import SecurityModal from './SecurityModal'

import { AppRoute } from 'assets/const'
import { useMediaSize } from 'hooks/useMediaSize'
import { useWithdrawData } from 'hooks/useWithdrawData'
import { createCustomListener } from 'providers/listeners'
import { useWithdrawMutation } from 'services/operations'
import { useCreateTemplateMutation } from 'services/templates'
import {
  updateSavedTemplateSuccessAction,
  withdrawFailedAction,
} from 'store/actions'
import { Verification, WithdrawReqData } from 'types/requests'
import {
  PSDefaultTemplate,
  PSTemplateRequest,
  PaymentFormDefinitionModel,
} from 'types/template'
import { toastError } from 'utils/toast'


const Withdraw = () => {
  const { isPhone } = useMediaSize()

  const [isFetchingWithdraw, setIsFetchingWithdraw] = useState<boolean>(false)
  const [withdrawData, setWithdrawData] = useState<WithdrawReqData | null>(null)
  const [activeTemplate, setActiveTemplate] = useState<PSDefaultTemplate | PSTemplateRequest | null>(null)
  const [modal, setModal] = useState<'SUCCESS_MODAL' | 'SECURITY_MODAL' | null>(null)

  const {
    currency,
    user,
    wallet,
    defaultTemplates,
    savedTemplates,
    isLoading,
    refetchSavedTemplates,
  } = useWithdrawData()
  const [withdraw, {isLoading: isWithdrawLoading}] = useWithdrawMutation()
  const [createTemplate] = useCreateTemplateMutation()
  const is2faEnabled = !!user?.multiFA?.type && user?.multiFA?.type !== 'none'

  useEffect(() => {
    if (!activeTemplate) return
    if (Array.isArray(activeTemplate.paymentFormDefinition)) {
      //это значит выбрали дефолтный темплейт( не из сохраненных) и делать ничего не нужно
      return
    }
    const foundTemplate = defaultTemplates?.find(
      (t) => t.code === activeTemplate.code
    )

    if (!foundTemplate) {
      toastError('Template not found')
      return
    }

    const template: PSDefaultTemplate = {
      id: activeTemplate.id,
      name: activeTemplate.name,
      code: activeTemplate.code,
      psCode: foundTemplate.psCode,
      paymentFormDefinition: foundTemplate.paymentFormDefinition,
      values: activeTemplate.paymentFormDefinition,
    }

    setActiveTemplate(template)
  }, [activeTemplate])

  useEffect(() => {
    const unsubscribe = createCustomListener({
      actionType: withdrawFailedAction.type,
      effect: () => {
        setIsFetchingWithdraw(false)
      },
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const unsubscribe = createCustomListener({
      actionType: updateSavedTemplateSuccessAction.type,
      effect: () => {
        refetchSavedTemplates()
          .unwrap()
          .finally(() => {
            toast.success('Template successfully saved')
            setIsFetchingWithdraw(false)
          })
      },
    })
    return unsubscribe
  }, [])

  const handleWithdrawSubmit = async (data: WithdrawReqData) => {
    if (!data) return
    if (!is2faEnabled) {
      await withdraw(data).unwrap().then(() => setModal('SUCCESS_MODAL'))
      setIsFetchingWithdraw(true)
    } else {
      setWithdrawData(data)
      setModal('SECURITY_MODAL')
    }
  }

  const handleCreateTemplate = (templateToSave: PaymentFormDefinitionModel) => {
    if (activeTemplate) {
      createTemplate({
        ...activeTemplate,
        paymentFormDefinition: templateToSave,
      })
    }
  }

  const handleSecuritySubmit = async (verification: Verification) => {
    console.log('verification ', verification)
    if (!withdrawData) return
    const data = { ...withdrawData }
    await withdraw(data)
    setIsFetchingWithdraw(true)
    setModal(null)
  }

  if (isLoading) {
    return <Preloader />
  }

  if (!defaultTemplates || !wallet || !currency) {
    return <Navigate to={`/${AppRoute.NotFound}`} />
  }

  return (
    <>
      <Container maxWidth={isPhone ? 'sm' : 'lg'} disableGutters sx={{display: 'flex', flexDirection: 'column'}}>
        <BackButton />
        <Paper
          sx={{
            p: {xs: 2, sm: 3},
            maxWidth: '661px',
            alignSelf: {xs: 'center', md: 'start'},
            ml: {sm: 0, md: 9}
          }}>
          <Typography variant={isPhone ? 'h3' : 'h2'}>Create withdraw request</Typography>
          <Box sx={{ mt: '12px'}}>
            <Typography fontSize={14}>
              Clever Wallet Cashout Hours are Monday through Friday 9am to 5pm EST
              excluding US Holidays. Our team will work as quickly as possibly to
              get your funds in your hands, but please understand that some cashouts
              may require manual review which may lead to a delay. As always, thank
              you for your patience!
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: 3,
              mx: { xs: 'auto', md: '0' },
            }}
          >
            <Account
              defaultTemplates={defaultTemplates}
              savedTemplates={savedTemplates || []}
              activeTemplate={activeTemplate}
              setActiveTemplate={setActiveTemplate}
            />
            { activeTemplate && (
              <Details
                handleWithdrawSubmit={handleWithdrawSubmit}
                activeTemplate={activeTemplate as PSDefaultTemplate}
                wallet={wallet}
                currency={currency}
                isFetching={isFetchingWithdraw}
                handleCreateTemplate={handleCreateTemplate}
              />
            )}
          </Box>
          <StripeDialog
            walletType={wallet.type}
            connectedAccountId={
              user?.businessSettings?.paymentSettings?.stripe?.connectedAccountId
            }
            isOperationAllowed={
              user?.businessSettings?.paymentSettings?.stripe?.canPayout
            }
          />
        </Paper>
      </Container>
      {modal === 'SECURITY_MODAL' && (
        <SecurityModal
          isFetching={isFetchingWithdraw}
          handleSecuritySubmit={handleSecuritySubmit}
          loading={isWithdrawLoading}
          onClose={() => setModal(null)}
        />
      )}
      {modal === 'SUCCESS_MODAL' && (<SuccessModal onClose={() => setModal(null)}/>)}
    </>
  )
}

export default Withdraw