import { Outlet, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import Footer from 'components/Footer'
import Header from 'components/Header'
import SideBar from 'components/Sidebar'
import MobileBar from 'components/MobileBar'

import { useMediaSize } from 'hooks/useMediaSize'

import s from './styles'


const MainLayout = () => {
  const { isMobileDevice } = useMediaSize()
  const {pathname} = useLocation()

  const drawerVariant =
    pathname === '/' || pathname.startsWith('/walletId') || pathname.startsWith('/operation-info') ? 'Dashboard'
    : pathname.startsWith('/profile') ? 'Profile'
    : null

  return (
    <Box sx={s.wrapper}>
      <Header drawerVariant={drawerVariant}/>
      <Box mt='75px'>
        { drawerVariant && isMobileDevice  && <MobileBar layout={drawerVariant}/> }
      </Box>
      <Box component="main" sx={s.container}>
        { drawerVariant && !isMobileDevice  && <SideBar layout={drawerVariant}/> }
        <Outlet />
      </Box>
      <Footer />
    </Box>

  )
}

export default MainLayout