import { Dispatch, FC, SetStateAction } from 'react'
import {
  Box,
  Paper,
  Typography,
} from '@mui/material'

import StatusLabel from 'components/StatusLabel'

import { Withdraw } from 'types/operation'
import { formatAmountForTable, formatDate, formatTime } from 'utils/intlFormat'
import { OperationStatus } from 'assets/const'
import { getOperationValue } from 'utils/operation'
import { theme } from 'theme'


interface Props {
  withdraw: Withdraw
  currencySymbol?: string
  setWithdraw: Dispatch<SetStateAction<Withdraw | null>>
}

const WithdrawCard: FC<Props> = ({withdraw, currencySymbol, setWithdraw}) => {
  const { id, updated, psCode, status, comment, amount, commission } = withdraw

  return (
    <Paper elevation={0} sx={{p: 2, mb: 2}} onClick={() => setWithdraw(withdraw)}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography fontSize={14}>{formatDate(updated)}</Typography>
          <Typography fontSize={12} color={theme.palette.gray.dark}>{formatTime(updated)}</Typography>
        </Box>
        <StatusLabel
          status={status}
          sx={{ fontSize: 12, margin: 0 }}
          label={getOperationValue(status, OperationStatus)}
        />
      </Box>
      <Box display='flex' alignItems='end' justifyContent='space-between' mt={2}>
        <Box>
          <Typography fontSize={14}>{psCode}</Typography>
          <Typography fontSize={12}>{comment}</Typography>
        </Box>
        <Typography fontSize={14}>{formatAmountForTable(amount, currencySymbol)} </Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' mt={2}>
          <Typography fontSize={12}>Commission</Typography>
          <Typography fontSize={14}>{currencySymbol} {commission}</Typography>
        </Box>
    </Paper>
  )
}

export default WithdrawCard