import { useEffect } from 'react'
import { Box, Divider, Typography } from '@mui/material'

import CardComponent from 'components/PaymentCard'

import { getPaymentIcon} from 'assets/const'
import {PSDefaultTemplate, PSTemplateRequest} from 'types/template'
import { useMediaSize } from 'hooks/useMediaSize'


interface Props {
  setActiveTemplate: (code: (PSDefaultTemplate | PSTemplateRequest | null)) => void
  defaultTemplates: PSDefaultTemplate[]
  savedTemplates: PSTemplateRequest[]
  activeTemplate: PSDefaultTemplate | PSTemplateRequest | null
}

const Account = ({
  defaultTemplates,
  setActiveTemplate,
  activeTemplate,
}: Props) => {
  const { isPhone } = useMediaSize()
  useEffect(() => {
    !activeTemplate && setActiveTemplate(defaultTemplates[0])
  }, [defaultTemplates])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
        <Typography variant={isPhone ? 'h4' : 'h3'} sx={{ pb: 3 }}>
          Select method of disbursement
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '14px' }}>
          {defaultTemplates?.map((template) => (
            <CardComponent
              key={template.code}
              activeTemplate={activeTemplate}
              setActive={setActiveTemplate}
              icon={getPaymentIcon[template.psCode]}
              template={template}
              isSmall
            />
          ))}
        </Box>
        <Divider sx={{my: 3}}></Divider>
      {/* {!!savedTemplates.length && (
        <Box sx={{ mb: 3 }}>
          <Typography variant='h3' sx={{ paddingBottom: 2, pl: 2 }}>
            Or select saved template:
          </Typography>
          <ScrollableBox maxHeight={220} width='100%'>
            {savedTemplates.map((template, index) => (
              <TemplateCard
                activeTemplate={activeTemplate}
                setActiveTemplate={setActiveTemplate}
                template={template}
                key={template.id}
                icon={
                  getPaymentIconByCode[template.code as keyof typeof getPaymentIconByCode]
                }
                index={index}
              />
            ))}
          </ScrollableBox>
        </Box>
      )} */}
    </Box>
  )
}

export default Account
