import { countDecimals } from 'utils/helpers'
import * as yup from 'yup'

export const transferSchema = yup.object({
  amount: yup
    .number()
    .required('Please enter the payment amount')
    .typeError('Please enter a valid number')
    .test(
      'isMaxDecimals',
      `Please enter a valid number`,
      (value, testContext) => {
        const maxDecimals = testContext.options.context?.maxDecimals

        if (!maxDecimals) {
          return true
        }

        return countDecimals(String(value)) > maxDecimals
          ? testContext.createError({
              message: `Decimal part should contain no more than ${maxDecimals} digits`,
            })
          : true
      }
    )
    .test(
      'isMinValidAmount',
      `Please enter a valid number`,
      (value, testContext) => {
        const minValidAmount = testContext.options.context?.minValidAmount

        if (
          (!value && value !== 0) ||
          (!minValidAmount && minValidAmount !== 0)
        ) {
          return true
        }

        return value <= minValidAmount
          ? testContext.createError({
              message: `Amount should be bigger then ${minValidAmount}`,
            })
          : true
      }
    )
    .test(
      'isEnoughBalance',
      `Please enter a valid number`,
      (value, testContext) => {
        const balance = testContext.options.context?.balance

        if (!balance || !value) {
          return true
        }

        return Number(value) > Number(balance)
          ? testContext.createError({
              message: `Please make a deposit and try again. Available balance: ${balance}`,
            })
          : true
      }
    ),

  description: yup.string(),
  toWallet: yup
    .string()
    .test(
      'isRequired',
      `Please, select a wallet to transfer`,
      (value, testContext) => {
        const activeTab = testContext.options.context?.activeTab

        return activeTab === 'toWallet' && !value
          ? testContext.createError({
              message: `Please, select a wallet to transfer`,
            })
          : true
      }
    ),
  toAccount: yup
    .string()
    .test('isRequired', `Please, enter a user name`, (value, testContext) => {
      const activeTab = testContext.options.context?.activeTab
      return activeTab === 'toAccount' && !value
        ? testContext.createError({
            message: `Please, enter a user name`,
          })
        : true
    }),
})
