import { Box, Tab, Tabs } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { Navigate } from 'react-router-dom'

import Preloader from 'components/Preloader'
import TabPanel from 'components/TablePanel'
import ProfileLayout from 'pages/profile/ProfileLayout'
import Activation from './Activation'
import Settings from './Settings'

import { AppRoute } from 'assets/const'
import { useFetchUserProfileQuery } from 'services/user'


const AuthenticationSettings = () => {
  const [value, setValue] = useState(0)
  const {
    data: user,
    isLoading: isUserLoading,
    isFetching,
  } = useFetchUserProfileQuery()
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if (!user && !isUserLoading) {
    return <Navigate to={`/${AppRoute.NotFound}`} />
  }

  return (
    <ProfileLayout title='2-factor authentication'>
      {isFetching && <Preloader />}
      {isUserLoading ? (
        <Preloader />
      ) : (
        <Box>
          <Tabs
            centered
            variant='fullWidth'
            value={value}
            onChange={handleChange}
            sx={{ borderBottom: 1, borderColor: 'divider', my: 4, maxWidth: {xs: '100%', sm: '324px'} }}
          >
            <Tab label='Activation' sx={{minWidth: {xs: '140px', sm: '162px'}}}/>
            <Tab label='Settings' sx={{minWidth: {xs: '140px', sm: '162px'}}}/>
          </Tabs>
          <TabPanel value={value} index={0}>
            <Activation
              user={user}
              isFetching={isFetching}
              key={user?.multiFA.type}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Settings user={user} />
          </TabPanel>
        </Box>
      )}
    </ProfileLayout>
  )
}

export default AuthenticationSettings
