import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Operation } from 'types/operation'
import {
  GetOperationsReq,
  GetWithdrawalsReq,
  RefillReqData,
  TransferReqData,
  WithdrawReqData,
} from 'types/requests'
import {
  GetOperationsResp,
  GetWithdrawalsResp,
  RefillResp,
  StatusResp,
} from 'types/responses'

import { portalApiUrl } from 'assets/const'
import {
  adaptOperationsFilterValues,
  adaptWithdrawFilterValues,
} from 'utils/helpers'
import { addSessionId } from 'utils/uuid'
import baseQuery from '../api'

export const operationsApi = createApi({
  reducerPath: 'operationsApi',
  baseQuery,
  tagTypes: ['Operations', 'Operation', 'Withdrawals'],
  endpoints: (build) => ({
    getAllWalletOperations: build.query<GetOperationsResp, GetOperationsReq>({
      query: ({ id, filter, paging }) => ({
        url: `wallet/${id}/operations`,
        method: 'POST',
        body: { filter: adaptOperationsFilterValues(filter), paging },
      }),
      providesTags: ['Operations'],
    }),
    getAllWithdrawals: build.query<GetWithdrawalsResp, GetWithdrawalsReq>({
      query: ({ filter, ...rest }) => ({
        url: `withdraw/history`,
        method: 'POST',
        body: { filter: adaptWithdrawFilterValues(filter), ...rest },
      }),
      providesTags: ['Withdrawals'],
    }),
    getWithdrawalsById: build.query<GetWithdrawalsResp, GetWithdrawalsReq>({
      query: ({ filter, walletId, ...rest }) => ({
        url: `withdraw/history/${walletId}`,
        method: 'POST',
        body: { filter: adaptWithdrawFilterValues(filter), ...rest },
      }),
      providesTags: ['Withdrawals'],
    }),
    getOperation: build.query<Operation, string>({
      query: (id) => `operation/${id}`,
      providesTags: ['Operation'],
    }),
    refill: build.mutation<RefillResp, RefillReqData>({
      query: (data) => ({
        url: `${portalApiUrl}invoice/token`,
        method: 'POST',
        body: data,
      }),
    }),
    withdraw: build.mutation<StatusResp, WithdrawReqData>({
      query: (data) => ({
        url: `wallet/${data.walletId}/withdraw/create`,
        method: 'POST',
        body: addSessionId(data),
      }),
    }),
    transferInternal: build.mutation<StatusResp, TransferReqData>({
      query: ({ id, ...data }) => ({
        url: `wallet/${id}/transfer/internal`,
        method: 'POST',
        body: addSessionId(data),
      }),
    }),
    transferExternal: build.mutation<StatusResp, TransferReqData>({
      query: ({ id, ...data }) => ({
        url: `wallet/${id}/transfer/external`,
        method: 'POST',
        body: addSessionId(data),
      }),
    }),
  }),
})

export const {
  useGetAllWalletOperationsQuery,
  useGetOperationQuery,
  useGetAllWithdrawalsQuery,
  useGetWithdrawalsByIdQuery,
  useRefillMutation,
  useWithdrawMutation,
  useTransferExternalMutation,
  useTransferInternalMutation,
} = operationsApi
