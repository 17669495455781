import { fetchApi } from 'api/fetch-api'
import { baseApiUrl } from 'assets/const'
import { Configuration } from 'types/configuration'

export const ConfigurationService = {
  async getConfiguration(): Promise<Configuration> {
    try {
      const response = await fetchApi(`${baseApiUrl}configuration`, {
        headers: { 'Content-Type': 'application/json' },
        retries: 3,
      })

      if (response.ok) {
        return await response.json()
      }
        const error = await response.json()
        return Promise.reject(error)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
}