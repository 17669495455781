import { countDecimals } from 'utils/helpers'
import * as yup from 'yup'

export const refillSchema = yup.object({
  amount: yup
    .number()
    .required('Please enter the payment amount')
    .typeError('Please enter a valid number')
    .test(
      'isMaxDecimals',
      `Please enter a valid number`,
      (value, testContext) => {
        const maxDecimals = testContext.options.context?.maxDecimals

        if (!maxDecimals) {
          return true
        }

        return countDecimals(String(value)) > maxDecimals
          ? testContext.createError({
              message: `Decimal part should contain no more than ${maxDecimals} digits`,
            })
          : true
      }
    )
    .test(
      'isMinValidAmount',
      `Please enter a valid number`,
      (value, testContext) => {
        const minValidAmount = testContext.options.context?.minValidAmount

        if (
          (!value && value !== 0) ||
          (!minValidAmount && minValidAmount !== 0)
        ) {
          return true
        }

        return value <= 0
          ? testContext.createError({
              message: `Amount should be bigger then ${minValidAmount}`,
            })
          : true
      }
    ),
  description: yup.string(),
})
