import { Box, Paper, Typography } from '@mui/material'
import { ReactComponent as EmptyListIcon } from 'assets/svg/EmptyList.svg'


const MobileEmptyListForOperations = () => {
  return (
    <Paper
      sx={{
        width: '100%',
        height: '130px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <EmptyListIcon />
      <Typography
        sx={{
          fontSize: '14px',
          textAlign: 'center',
          color: 'gray.main',
          marginTop: '14px',
        }}
      >
        The list is empty
      </Typography>
    </Paper>
  )
}

export default MobileEmptyListForOperations
