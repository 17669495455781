import Preloader from 'components/Preloader'
import ProfileLayout from '../profile/ProfileLayout'
import NewTemplate from './NewTemplate'
import SavedTemplate from './SavedTemplate'

import { createCustomListener } from 'providers/listeners'
import { useEffect, useState } from 'react'
import {
  useGetUserDefaultTemplatesQuery,
  useGetUserSavedTemplatesQuery,
} from 'services/templates'
import {
  updateSavedTemplateFailedAction,
  updateSavedTemplateSuccessAction,
} from 'store/actions'


const WithdrawTemplates = () => {
  const [isSavedUpdating, setIsSavedUpdating] = useState(false)
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const {
    data: savedTemplates,
    isLoading: isSavedLoading,
    refetch: refetchSavedTemplates,
  } = useGetUserSavedTemplatesQuery()
  const { data: defaultTemplates, isLoading: isDefaultLoading } =
    useGetUserDefaultTemplatesQuery()

  useEffect(() => {
    const unsubscribe = createCustomListener({
      actionType: updateSavedTemplateFailedAction.type,
      effect: () => {
        setIsSavedUpdating(false)
      },
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const unsubscribe = createCustomListener({
      actionType: updateSavedTemplateSuccessAction.type,
      effect: () => {
        refetchSavedTemplates()
          .unwrap()
          .finally(() => {
            setIsAddNewModalOpen(false)
            setIsEditModalOpen(false)
            setIsSavedUpdating(false)
          })
      },
    })
    return unsubscribe
  }, [])

  return (
    <ProfileLayout title='Payment method settings'>
      {isSavedLoading || isDefaultLoading ? (
        <Preloader />
      ) : (
        <>
          {isSavedUpdating && <Preloader />}
          {!!savedTemplates?.length && !!defaultTemplates?.length && (
            <SavedTemplate
              isSavedUpdating={isSavedUpdating}
              savedTemplates={savedTemplates}
              defaultTemplates={defaultTemplates}
              isModalOpen={isEditModalOpen}
              setIsSavedUpdating={setIsSavedUpdating}
              setIsModalOpen={setIsEditModalOpen}
            />
          )}

          {!!defaultTemplates?.length && (
            <NewTemplate
              defaultTemplates={defaultTemplates}
              isSavedUpdating={isSavedUpdating}
              isModalOpen={isAddNewModalOpen}
              setIsSavedUpdating={setIsSavedUpdating}
              setIsModalOpen={setIsAddNewModalOpen}
            />
          )}
        </>
      )}
    </ProfileLayout>
  )
}

export default WithdrawTemplates
