import { SxProps, Theme } from '@mui/material'
import Vector from 'assets/images/Vector.png'
import SelectedVector from 'assets/images/SelectedVector.png'

const tabs: SxProps = {
  pt: 1,
  height: 'calc(100dvh - 359px)',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiTabs-flexContainer': {gap: '16px'}
}

const balance: SxProps = {
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '0em',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

const reserved: SxProps = {
  fontSize: '10px',
  fontWeight: 500,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

const name: SxProps = {
  fontSize: '14px',
  textTransform: 'uppercase',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0em',
  position: 'absolute',
  top: '8px',
  left: '8px',
  right: '1px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  zIndex:2
}

const tab: SxProps = {
  minHeight: '100%',
  minWidth: '100%',
  padding: '8px',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '19px',
  letterSpacing: '0em',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'baseline',
  zIndex:3,

  '&.Mui-selected': {
    border: 'none',
    color: 'text.primary',
  },
}

const label = (selected: boolean): SxProps<Theme> => ({
  color: (theme) =>
    selected
      ? theme.palette.text.primary
      : theme.palette.text.contrast
})

const getWrapperStyles = (selected: boolean): SxProps<Theme> => ({
  height: '100px',
  minHeight: '100px',
  borderRadius: '8px',
  position: 'relative',
  alignSelf: 'center',
  transition: 'all .1s',
  width: selected ? '180px' : '160px',
  background:
    `url(${selected ? SelectedVector : Vector}) no-repeat right bottom, ${
      selected ? '#FFFFFF' : 'transparent'
    }`,

  border: (theme) =>
    selected ? 'none' : '1px #FFFFFF solid',

  color: (theme) =>
    selected
      ? theme.palette.text.primary
      : theme.palette.primary.contrastText

})

const getIconStyles = (selected: boolean): SxProps<Theme> => ({
  color: `${selected ? 'primary.light' : 'gray.dark'}`,
  transform: 'scale(1.3)',
  position: 'absolute',
  top: '8px',
  left: '4px',
})

const Styles = {
  tabs,
  name,
  tab,
  label,
  getWrapperStyles,
  getIconStyles,
  balance,
  reserved
}

export default Styles