import Box, { BoxProps } from '@mui/material/Box'
import { theme } from 'theme'

interface Props extends BoxProps {}

const ScrollableBox = ({ sx, children, ...rest }: Props) => {
  return (
    <Box
      {...rest}
      sx={{
        overflow: 'auto',
        pr: '8px',
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.primary.main} #f0f0f8`,
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f0f0f8',
          borderRadius: '0 6px 6px 0',
          overflow: 'auto',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'linear-gradient(180deg, #6312F3 0%, #3F03A9 100%)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#7439dd',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default ScrollableBox
