import { createSelector } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useFetchAllCurrenciesQuery } from 'services/dictionaries'
import { Currency } from 'types/currency'

export const useCurrencyByCode = (code: string | undefined) => {
  const selectCurrencyByCode = useMemo(() => {
    return createSelector(
      (response: Currency[] | undefined) => response,
      (response) =>
        response?.find((c) => {
          return c.code === code
        })
    )
  }, [code])

  const { currency, isLoadingCurrency } = useFetchAllCurrenciesQuery(
    undefined,
    {
      skip: code === undefined,
      selectFromResult: ({ data, isLoading }) => ({
        currency: selectCurrencyByCode(data),
        isLoadingCurrency: isLoading,
      }),
    }
  )

  return { currency, isLoadingCurrency }
}
