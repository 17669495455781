import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Currency } from 'types/currency'
import { CurrencyListResponse } from 'types/responses'
import baseQuery from '../api'

export const dictionariesApi = createApi({
  reducerPath: 'dictionariesApi',
  baseQuery,
  tagTypes: ['Currencies', 'CurrenciesType'],
  endpoints: (build) => ({
    fetchAllCurrencies: build.query<Currency[], void>({
      query: () => ({
        url: `dict/currencies`,
        method: 'GET',
      }),
      providesTags: ['Currencies'],
      transformResponse: (response: CurrencyListResponse) => response.list,
    }),
    fetchOperationTypesNames: build.query<string[], void>({
      query: () => ({
        url: `dict/operation/types/names`,
        method: 'GET',
      }),
      transformResponse: (response: {typeNames: string[]}) => response.typeNames,
    }),
  }),
})

export const { useFetchAllCurrenciesQuery, useFetchOperationTypesNamesQuery } = dictionariesApi
