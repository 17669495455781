import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { errorLogger } from 'api/middlewareApi'
import { dictionariesApi } from 'services/dictionaries'
import { operationsApi } from 'services/operations'
import { templatesApi } from 'services/templates'
import { userApi } from 'services/user'
import { walletsApi } from '../services/wallets'
import { appReducer } from './app-reducer'
import {dashboardReducer} from './dashboard-reducer'
import {actionListener} from '../providers/listeners';
import { currenciesApi } from 'services/currency'


const rootReducer = combineReducers({
  [walletsApi.reducerPath]: walletsApi.reducer,
  [operationsApi.reducerPath]: operationsApi.reducer,
  [dictionariesApi.reducerPath]: dictionariesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [currenciesApi.reducerPath]: currenciesApi.reducer,
  [templatesApi.reducerPath]: templatesApi.reducer,
  app: appReducer,
  dashboard: dashboardReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).prepend(actionListener.middleware)
    .concat(
      walletsApi.middleware,
      operationsApi.middleware,
      dictionariesApi.middleware,
      templatesApi.middleware,
      userApi.middleware,
      currenciesApi.middleware,
      errorLogger
    ),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>
export type RootReducerType = typeof rootReducer
export type AppRootStateType = ReturnType<RootReducerType>
export type AppDispatch = typeof store.dispatch
