import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {Box, Button, CircularProgress, Typography} from '@mui/material'

import Modal from 'components/Modal'
import ScrollableBox from 'components/ScrollableBox'
import TableButton from 'components/AddWalletModal/TableButton'
import StepsLinearProgress from './StepsLinearProgress'

import { useAddWalletMutation, useGetAvailableWalletsQuery } from 'services/wallets'
import { getDefaultName } from 'utils/text'
import { WalletsPaymentSystem, WalletType } from 'types/wallet'

import s from './styles'


interface Props {
  handleClose:()=>void
}
const AddWalletModal:FC<Props> = ({handleClose}) => {
  const [, setSearchParams] = useSearchParams()

  const [step, setStep] = useState<number>(1)
  const [activeCurrency, setActiveCurrency] = useState<string | null>(null)
  const [activeWalletType, setActiveWalletType] = useState<WalletType | null>(null)
  const [activePaymentSystem, setActivePaymentSystem] = useState<WalletsPaymentSystem | null>(null)
  const isFirstStep = step === 1

  const [addWallet, {isLoading}] = useAddWalletMutation()
  const { data: availableWallets, isLoading: isLoadingWalletTypes } = useGetAvailableWalletsQuery()

  const handlePaymentSystemChange = (paymentSystem: WalletsPaymentSystem, walletType: WalletType) => {
    setActivePaymentSystem(paymentSystem)
    setActiveWalletType(walletType)
  }

  const handleCurrencyChange = (currency: string) => {
    setActiveCurrency(currency)
  }

  const handleStepChange = () => {
    setStep(isFirstStep ? 2 : 1)
  }

  const onSubmit = () => {
    if (activeWalletType && activeCurrency) {
      addWallet({
        currency: activeCurrency,
        name: getDefaultName(activeCurrency),
        isDefault: false,
        walletType: activeWalletType,
      }).unwrap()
        .then((resp)=> {
          handleClose()
          setSearchParams(prevParams => {
            const params = new URLSearchParams(prevParams)
            params.set('wallet', resp.id)
            params.set('currency', resp.currency)
            params.set('excludeEmpty', 'false')
            return params
          })
        })
    }
  }

  const isActionButtonDisabled = isLoading || (isFirstStep && !activePaymentSystem) || (!isFirstStep && !activeCurrency)

  return (
    <Modal
      open
      onClose={handleClose}
      handleClose={handleClose}
      title='Add new wallet'
    >
        <Box sx={s.container}>
          <StepsLinearProgress isFirstStep={isFirstStep}/>
          <Box sx={s.content}>
            {isLoadingWalletTypes && <CircularProgress />}

            {availableWallets &&
              <>
                {isFirstStep ?
                <Box sx={s.fitstStepBox}>
                  <Typography variant='h5' sx={s.selectPaymentText}>Select a payment system</Typography>
                  <ScrollableBox sx={s.tabsWrapper}>
                    {Object.entries(availableWallets).map(([paymentSystem, content]) => (
                      <TableButton
                        key={paymentSystem}
                        currency={paymentSystem}
                        isPaymentSystem
                        isActiveWallet={activePaymentSystem === paymentSystem}
                        handleClick={() => handlePaymentSystemChange(paymentSystem as WalletsPaymentSystem, content.walletType)}
                      />
                    ))}
                  </ScrollableBox>
                </Box>
                :
                <>
                  <ScrollableBox sx={s.tabsWrapper}>
                    {activePaymentSystem && availableWallets[activePaymentSystem].supportedCurrencies?.length ? (
                      availableWallets[activePaymentSystem].supportedCurrencies.map((currency) => (
                        <TableButton
                          key={currency}
                          currency={currency}
                          isActiveWallet={activeCurrency === currency}
                          handleClick={() => handleCurrencyChange(currency)}
                        />
                      ))
                    ) : (
                      <Typography alignSelf='center'>Wallets are not available</Typography>
                    )}
                  </ScrollableBox>
                </>
                }
              </>
            }
          </Box>

          <Box sx={s.buttonsContainer}>
            {!isFirstStep &&
              <Button
                variant='outlined'
                onClick={handleStepChange}
                sx={s.button}
              >
                Back
              </Button>
            }
              <Button
                variant='contained'
                onClick={isFirstStep ? handleStepChange : onSubmit}
                sx={s.button}
                disabled={isActionButtonDisabled}
              >
                {isFirstStep ? 'Next' : 'Create'}
              </Button>
          </Box>

        </Box>
    </Modal>
  )
}

export default AddWalletModal