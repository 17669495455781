import { useMemo } from 'react'
import { PSDefaultTemplate } from 'types/template'

export const useTemplateFields = (
  template: PSDefaultTemplate
  // activeMethod: string
) => {
  const fields = useMemo(
    () =>
      template.paymentFormDefinition.slice().sort((a, b) => a.order - b.order),
    [template]
  )
  return {
    fields,
  }
}
