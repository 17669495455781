import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import Select from 'components/Select'
import TextInput from 'components/TextInput'

import { AuthLabel, AuthType, authenticationDisableOption } from 'assets/const'
import { useMediaSize } from 'hooks/useMediaSize'
import { useSetMultiFAMethodMutation } from 'services/user'
import { MultiFAOperations, MultiFAType, UserProfile } from 'types/user'
import { activationSchema } from './validation'


interface Props {
  user: UserProfile | undefined
  isFetching: boolean
}

interface FormValues extends MultiFAOperations {
  type: MultiFAType
  code: string
}

const Activation = ({ user, isFetching }: Props) => {
  const { isMobileDevice, inputSize } = useMediaSize()
  const [setMethod, { isLoading: isMethodFetching }] =
    useSetMultiFAMethodMutation()

  const isMethodAlreadySet = user?.multiFA?.type !== AuthType.none

  const authenticationOptions = useMemo(() => {
    const allowed = user?.multiFA.allowedMethods.map((value) => ({
      value: AuthType[value],
      label: AuthLabel[value],
    }))

    return allowed
      ? [authenticationDisableOption, ...allowed]
      : [authenticationDisableOption]
  }, [user?.multiFA.allowedMethods])

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
    setError,
    getFieldState,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(activationSchema),
    defaultValues: {
      type: user?.multiFA?.type,
      transfer: !!user?.multiFA?.operations.transfer,
      withdraw: !!user?.multiFA?.operations.withdraw,
      payment: !!user?.multiFA?.operations.payment,
      gift: !!user?.multiFA?.operations.refill,
      refill: !!user?.multiFA?.operations.withdraw,
      code: '',
    },
    context: {
      isMethodAlreadySet,
    },
  })

  const currentSelected = watch('type')
  const isActiveSelected = user?.multiFA?.type === currentSelected
  const isTypeTouched = getFieldState('type').isDirty

  const handleCancel = () => {
    reset()
  }

  const onSubmit = async (formData: FormValues) => {
    await setMethod({
      type: formData.type,
      allowedMethods: user?.multiFA?.allowedMethods ?? [],
      operations: {
        transfer: formData.transfer,
        withdraw: formData.withdraw,
        payment: formData.payment,
        gift: formData.gift,
        refill: formData.refill,
      },
      verification: {
        code: formData.code,
        request: '',
      },
    })
      .unwrap()
      .then(() => {})
      .catch(() =>
        setError('code', {
          type: 'validate',
          message: 'Please enter a valid code',
        })
      )
  }

  return (
    <Box
      component={'form'}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{ background: '#FFFFFF', maxWidth: {xs: '100%', sm: '324px'} }}
    >
      <Box display='flex'>
        <Select
          control={control}
          name='type'
          label=''
          options={authenticationOptions}
          errors={errors}
        />
        {isActiveSelected && isMethodAlreadySet && (
          <CheckCircleRoundedIcon
            color='success'
            sx={{alignSelf: 'center', justifyContent: 'center', ml: 1}}
          />
        )}
      </Box>


      {/*  //hide in v.1

          <Typography variant='h3'>
            Require 2FA in the following cases:
          </Typography>
          <Box sx={{ pb: 3, pt: 2 }}>
            <Checkbox
              control={control}
              name={'save'}
              label='Payments (wallet and credit card)'
            />
          </Box>

       */}
      {isTypeTouched && isMethodAlreadySet && (
        <>
          <Typography sx={{ pt: 4, pb: 3 }}>
            Enter code from Google Authenticator
          </Typography>
          <TextInput
            errors={errors}
            control={control}
            name={'code'}
            label={'Code'}
            size={inputSize}
            fullWidth
          />
        </>
      )}
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Button
          type='submit'
          variant='contained'
          fullWidth={isMobileDevice}
          disabled={
            !currentSelected ||
            !isTypeTouched ||
            isMethodFetching ||
            !isValid ||
            isFetching
          }
          sx={{flex: 1}}
        >
          Save
        </Button>
        <Button
          variant='outlined'
          type='reset'
          onClick={handleCancel}
          fullWidth={isMobileDevice}
          disabled={!currentSelected || !isTypeTouched}
          sx={{flex: 1}}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

export default Activation
