import { CSSProperties, FC, HTMLInputTypeAttribute } from 'react'
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'


interface TextInputProps extends StandardTextFieldProps {
  control: any
  name: string
  label: string
  rules?:
    | Omit<
        RegisterOptions<FieldValues>,
        'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'
      >
    | undefined
  errors?: any
  autoFocus?: boolean
  multiline?: boolean
  rows?: number
  onBlurHandler?: () => void
  disabled?: boolean
  size?: 'medium' | 'small'
  type?: HTMLInputTypeAttribute
  style?: CSSProperties
}

const TextInput: FC<TextInputProps> = ({
  control,
  errors,
  name,
  label,
  autoFocus,
  onBlurHandler,
  disabled,
  size,
  type,
  style,
  rules,
  ...rest
}) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, name, ref, onBlur } }) => (
        <TextField
          {...rest}
          onChange={onChange}
          value={value || ''}
          name={name}
          inputRef={ref}
          label={label}
          size={size}
          onBlur={onBlurHandler ?? onBlur}
          autoFocus={autoFocus}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          className='inputRounded'
          disabled={disabled}
          type={type}
          style={{ ...style }}
          fullWidth
        />
      )}
    />
  )
}

export default TextInput
