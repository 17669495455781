import * as yup from 'yup'

import { AuthType } from 'assets/const'

const PHONE_NO_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/

export const settingsSchema = yup.object().shape({
  code: yup.string().required('Please enter a valid code'),
  type: yup.string().required(),
  email: yup.string().when('type', {
    is: (val: string) => {
      return val === AuthType.email
    },
    then: yup
      .string()
      .required('A email address is required ')
      .email('Please enter a valid email address'),
  }),
  phone: yup.string().when('type', {
    is: (val: string) => {
      return val === AuthType.sms
    },
    then: yup
      .string()
      .required('A phone number is required ')
      .matches(PHONE_NO_REGEX, {
        message: 'Please enter a valid phone number',
        excludeEmptyString: true,
      }),
  }),
})
