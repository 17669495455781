import { ToastContainer } from 'react-toastify'

const ToastConfig = {
  Limit: 3,
  Delay: 3000,
  Newest: false,
  Position: 'top-right',
  ProgressBar: true,
}

const StyledToastContainer = () => {
  return (
    <ToastContainer
      limit={ToastConfig.Limit}
      autoClose={ToastConfig.Delay}
      hideProgressBar={ToastConfig.ProgressBar}
      newestOnTop={ToastConfig.Newest}
      closeOnClick
      draggable
      pauseOnHover
    />
  )
}

export default StyledToastContainer
