import { FC } from 'react'
import {  useSearchParams } from 'react-router-dom'
import { Box,  Paper, Theme, useMediaQuery } from '@mui/material'

import OperationButtons from './OperationButtons'
import WalletCarousel from './WalletCarousel'
import WalletDetail from './WalletDetail'
import WalletSettings from './WalletSettings'
import Preloader from '../Preloader'

import { Currency } from 'types/currency'
import { Wallet } from 'types/wallet'

import s from './styles'


interface Props {
  wallets: Wallet[]
  currency: Currency | undefined
}

const WalletInfo: FC<Props> = ({currency, wallets}) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isMobileDevice = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))
  const [searchParams] = useSearchParams()
  const wallet = searchParams.get('wallet')

  const currentWallet = wallets?.find((w)=>w.id === wallet)

  if (!currentWallet ) return <Preloader withLeftMargin/>

  return (
    <Paper sx={s.wrapper}>
      { isMobile ? (
        <WalletCarousel currentWallet={currentWallet} currency={currency}/>
      ) : (
        <>
          <Box display='flex' justifyContent='space-between'>
          <WalletDetail wallet={currentWallet} currency={currency}/>
          {isMobileDevice && <WalletSettings wallet={currentWallet}/>}
          </Box>
          <Box sx={s.operationContainer}>
            {!isMobileDevice && <WalletSettings wallet={currentWallet}/>}
            <OperationButtons />
          </Box>
        </>
      )}
    </Paper>
  )
}

export default WalletInfo