import { gravatarUrl } from 'assets/const'
import md5 from 'md5'

export const getAvatarInitials = (
  firstName: string = '',
  lastName: string = ''
) => firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()

export const getHash = (string: string) => md5(string)

export const getGravatarURL = (email: string) => {
  const address = String(email).trim().toLowerCase()
  return `${gravatarUrl}${getHash(address)}?d=404`
}
