import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';

import OperationCard from 'components/OperationCards/OperationCard'
import MobileEmptyListForOperations from 'components/MobileEmptyListForOperations';
import PreloaderForInfiniteScroll from 'components/PreloaderForInfiniteScroll';
import OperationInfoModal from 'components/OperationInfoModal';

import { useGetAllWalletOperationsQuery } from '../../services/operations'
import { useCurrencyByCode } from 'hooks/useCurrencyByCode'
import { Operation, OperationsFilterParams, OperationsPagingParams } from 'types/operation'
import { useSearchParams } from 'react-router-dom';


interface Props {
  filterParams: OperationsFilterParams
  pagingParams: OperationsPagingParams
  setPagingParams: (params: Partial<OperationsPagingParams>) => void
  operationId: string
  setOperationId: Dispatch<SetStateAction<string>>
}

const OperationCards: FC<Props> = ({
  filterParams,
  pagingParams,
  setPagingParams,
  operationId,
  setOperationId
}) => {
  const [searchParams] = useSearchParams()
  const walletId = searchParams.get('wallet')
  const currentCurrency = searchParams.get('currency') || ''
  const [operations, setOperations] = useState<Operation[]>([]);

  const { data, isLoading } = useGetAllWalletOperationsQuery(
    { paging: pagingParams, filter: filterParams, id: walletId ?? '' },
    { refetchOnMountOrArgChange: true }
  )
  const { currency, isLoadingCurrency } = useCurrencyByCode(
    currentCurrency
  )

  useEffect(() => {
     setOperations([])
  }, [filterParams, walletId])

  useEffect(() => {
    data && setOperations((prev) => ([...prev, ...data.content]))
  }, [data])

  const fetchMoreData = () => {
    setPagingParams({pageIndex: pagingParams.pageIndex + 1})
  };

  if (isLoading || isLoadingCurrency) return  <PreloaderForInfiniteScroll />

  return (
    <>
      {data && data?.content.length ?
        <InfiniteScroll
          dataLength={operations.length}
          next={fetchMoreData}
          hasMore={operations.length < data.totalElements}
          loader={<PreloaderForInfiniteScroll/>}
          endMessage={<Typography textAlign='center'>No more operations</Typography>}
        >
          {operations.map(operation =>
            <OperationCard key={operation.id} operation={operation} currencySymbol={currency?.symbol} setOperationId={setOperationId}/>
          )}
        </InfiniteScroll>
        :
        <MobileEmptyListForOperations/>
      }
      {operationId && <OperationInfoModal id={operationId} onClose={() => setOperationId('')}/>}
    </>
  )
}

export default OperationCards