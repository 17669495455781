import { FC } from 'react'
import { Box, Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme } from 'theme'
import {PSDefaultTemplate, PSTemplateRequest} from 'types/template'


interface Props {
  icon: string
  template: PSDefaultTemplate
  activeTemplate: PSDefaultTemplate | PSTemplateRequest | null
  setActive: (template: (PSDefaultTemplate | PSTemplateRequest | null)) => void
  isSmall?: boolean
  setIsModalOpen?: (isModalOpen: boolean) => void
}

const CardComponent: FC<Props> = ({
  template,
  icon,
  activeTemplate,
  setActive,
  isSmall,
  setIsModalOpen
}) => {
  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.down(744))

  return (
    <Box
      onClick={(evt) => {
        evt.stopPropagation()
        setActive(template)
        setIsModalOpen && setIsModalOpen(true)
      }}
      tabIndex={0}
      component='button'
      data-type='templateButton'
      sx={{
        maxWidth: isSmall ? '80px' : {xs: '120px', sm: '140px', md: '152px'},
        minWidth: isSmall ? '80px' : {xs: '120px', sm: '140px', md: '152px'},
        height: isSmall ? '40px' : '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#262626',
        border:
          template === activeTemplate
            ? `1px solid ${theme.palette.primary.main}`
            : '1px solid #EEF2F7',
        borderRadius: '8px',
        padding: isSmall ? '5px' : '10px',
        flexBasis: isTablet ? 'calc(100% / 6)' : 'calc(100% / 9)',
        background: '#FAFAFA',
        cursor: 'pointer',
        '&:hover': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:active': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      {icon && (
        <img src={icon} alt={`${template.psCode}`} data-type='templateButton' height={isSmall ? '32px' : '42px'}/>
      )}
    </Box>
  )
}

export default CardComponent
