import {
  Avatar,
  Box,
  IconButton,
} from '@mui/material'
import { useState } from 'react'

import AvatarCropper from 'components/AvatarCropper'
import DropZone from 'components/DropZone'
import Modal from 'components/Modal'
import AuthService from 'services/AuthService'

import { getGravatarURL } from 'utils/avatar'
import {ReactComponent as AddPhotIcon} from 'assets/svg/buttons/DownloadPhoto.svg'
import { theme } from 'theme'


const AvatarUpload = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState<string | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)

  const avatarUrl = croppedImage || getGravatarURL(AuthService.getUserEmail())

  const handleModalClose = () => {
    setIsOpen(false)
  }
  const handleModalOpen = () => {
    setIsOpen(true)
  }

  const handleSetImage = (image: string | null) => setImage(image)

  const handleSetCroppedImage = (image: string | null) => setCroppedImage(image)

  return (
    <Box alignSelf='center'>
      <IconButton
        sx={{ mr: {xs: 0, sm: 3, md: 5}, p: 0, }}
        aria-label='upload-avatar'
        onClick={handleModalOpen}
      >
        <Avatar alt='' src={avatarUrl} sx={{ width: 154, height: 154, bgcolor: 'gray.light' }}>
          <AddPhotIcon color={theme.palette.gray.main}/>
        </Avatar>
      </IconButton>
      <Modal
        handleClose={handleModalClose}
        open={isOpen}
        sx={{ overflow: 'hidden' }}
        title='Upload avatar'
      >
        <Box sx={{ minWidth: { sm: '500px' } }}>
          {!image ? (
            <DropZone setImage={handleSetImage} />
          ) : (
            <AvatarCropper
              image={image}
              croppedImage={croppedImage}
              setImage={handleSetImage}
              setCroppedImage={handleSetCroppedImage}
              handleModalClose={handleModalClose}
            />
          )}
        </Box>
      </Modal>
    </Box>
  )
}

export default AvatarUpload
