import { Box, Container, Link, Paper, Typography } from '@mui/material'
import TermsBox from './TermsBox'

import { aboutUsContent } from 'consts/text'
import { theme } from 'theme'

import { ReactComponent as EmailIcon } from 'assets/svg/contacts/Email.svg'
import { ReactComponent as PhoneIcon } from 'assets/svg/contacts/Phone.svg'
import { ReactComponent as LocationIcon } from 'assets/svg/contacts/Location.svg'


const polices = [
  {text: 'Acceptable Use Policy', path: ''},
  {text: 'Restricted Activities and Holds', path: ''},
  {text: 'CCPA Privacy Notice', path: ''},
  {text: 'Data Processing Addendum', path: ''},
  {text: 'Fee Disclosure', path: ''},
]

const More = () => {
  return (
    <Container maxWidth='lg' disableGutters>
      <Typography variant='h1' mb={3}> More </Typography>
      <Box display='flex' flexDirection={{xs: 'column', sm: 'row'}} gap={{xs: 2, sm: 3}}>
        <Box
          width={{xs: '100%', sm: '41%'}}
          display='flex'
          flexDirection='column'
          gap={{xs: 2, sm: 3}}
        >
          <Paper elevation={0} sx={{p: 3, flex: 1}}>
            <Typography variant='h2' mb={2}>About us</Typography>
            {aboutUsContent.map((text, index) =>
              <Typography key={index} fontSize={14} mb={2} textAlign='justify'>{text}</Typography>
            )}
          </Paper>

          <Paper elevation={0} sx={{p: 3}}>
            <Typography variant='h2' mb={2}>Contact us</Typography>
              <Link
                href='mailto:sales@cleverwallet.app'
                target='blank'
                sx={{display: 'flex', alignItems: 'center', gap: 1}}
              >
                <EmailIcon/>
                <Typography fontSize={14} color={theme.palette.text.primary}>sales@cleverwallet.app</Typography>
              </Link>
              <Link
                href='tel:+1 615-419-6646'
                target='_blank'
                sx={{display: 'flex', alignItems: 'center', mt: 2, gap: 1}}
              >
                <PhoneIcon/>
                <Typography fontSize={14} color={theme.palette.text.primary}>+1 615-419-6646</Typography>
              </Link>
              <Box sx={{display: 'flex', alignItems: 'center', mt: 2, gap: 1, '& svg': {minWidth: '24px'}}}>
                <LocationIcon/>
                <Typography fontSize={14} color={theme.palette.text.primary}>
                  We Can Develop IT LLC, <br/>12000 Ancient Crest Circle, Apt 12107, Franklin, Tennessee, 37067
                </Typography>
              </Box>
          </Paper>
        </Box>

        <Paper elevation={0} sx={{p: 3, width: {xs: '100%', sm: '59%'}, display: 'flex', flexDirection: 'column', gap: 4}}>
          <Typography variant='h2'>Universal Terms of Service</Typography>
          <TermsBox text='Terms of Service' link='terms'/>
          <Typography variant='h2'>General Police s/Notices</Typography>
          {polices.map(police =>
            <TermsBox key={police.text} text={police.text} link={police.path}/>
          )}
        </Paper>
      </Box>
    </Container>
  )
}

export default More