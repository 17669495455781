import { Container, Paper, Typography } from '@mui/material'

import FaqAccordion from './Accordion'

import { faqContent } from 'consts/text'


const Faq = () => {
  return (
    <Container maxWidth='lg' disableGutters>
      <Typography variant='h1' mb={3}> FAQ </Typography>
      <Paper
        elevation={0}
        sx={{
          p: '16px 24px',
          width: '100%',
        }}
      >
        {faqContent.map(faq =>
          <FaqAccordion key={faq.title} summary={faq.title} details={faq.details} />
        )}
      </Paper>
    </Container>
  )
}

export default Faq