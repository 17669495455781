import { Box, Button, List, ListItemText, Typography } from '@mui/material'
import { QRCodeSVG } from 'qrcode.react'
import { Control } from 'react-hook-form'

import TextInput from 'components/TextInput'

import { useMediaSize } from 'hooks/useMediaSize'


interface Props {
  qrCode: string
  control: Control<any>
  errors: any
  isValidate: boolean
  isFetchingCode: boolean
  isValid: boolean
  isDirty: boolean
  handleValidate: () => void
  handleCancel: () => void
}

const GoogleAuth = ({
  isValid,
  isDirty,
  qrCode,
  control,
  errors,
  isValidate,
  isFetchingCode,
  handleValidate,
  handleCancel,
}: Props) => {
  const { isMobileDevice } = useMediaSize()
  const TITLE = `Configure as follows:`
  const STEPS = [
    '1. Download the free app “Google Authenticator” on you mobile device.',
    '2. Open the app.',
    '3. Press the plus(“+”) button in the app',
    '4. Select “Scan a barcode” in the app',
    '5. Point your camera at the QR code shown to the left on this page. (That`s the square of black pixels)',
    '6. Inside the app, you will see an entry for CleverWallet',
    '7. Above it is a 6-digit code, which changes periodically. This is the code you would enter',
  ]

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: {xs: 'left', sm: 'center'},
          gap: 4,
        }}>
      <Box height='200px'>
        <QRCodeSVG value={qrCode} width={200} height={200} level={'Q'} />
      </Box>
      <Box>
        <List
          subheader={TITLE}
          sx={{
            gridColumn: !isMobileDevice ? '2 / 3' : 'auto',
            gridRow: !isMobileDevice ? '1 / 4' : 'auto',
            fontSize: 14,
            fontWeight: 400,
            p: 0,
          }}
        >
          {STEPS.map((step, i) => (
            <ListItemText key={`${i} step`} primary={step} sx={{'& .MuiTypography-root': {fontSize: 14}}}/>
          ))}
        </List>
      </Box>
      </Box>
      {/* {!isValidate ? (
        <Box>
          <Button
            variant='contained'
            fullWidth={isMobileDevice}
            onClick={handleValidate}
            sx={{mt: 3, width: {xs: '100%', sm: '324px'}}}
          >
            Validate 2fa type
          </Button>
        </Box>
      ) : ( */}
        <>
          <Box mb={4}>
            <Typography variant='h4' mt={4} mb={2}>
              Enter code from Google Authenticator
            </Typography>
            <TextInput
              errors={errors}
              control={control}
              name={'code'}
              label={'Code'}
              sx={{maxWidth: '324px'}}
            />
          </Box>
          <Box
            gap={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxWidth: '324px'
            }}
          >
            <Button
              type='submit'
              variant='contained'
              fullWidth={isMobileDevice}
              disabled={isFetchingCode || !isValid || !isDirty}
              sx={{flex: 1}}
            >
              Save
            </Button>

            <Button
              variant='outlined'
              type='reset'
              fullWidth={isMobileDevice}
              onClick={handleCancel}
              sx={{flex: 1}}
            >
              Cancel
            </Button>
          </Box>
        </>
      {/* )} */}
    </Box>
  )
}

export default GoogleAuth