import {
  ListenerEffect,
  ListenerMiddlewareInstance,
  PayloadAction,
  createAction,
  createListenerMiddleware,
} from '@reduxjs/toolkit'

export const actionListener = createListenerMiddleware()

export const createCustomListener = <T>({
  actionType,
  effect,
  listener = actionListener,
}: {
  actionType: string
  effect: ListenerEffect<PayloadAction<T>, any, any>
  listener?: ListenerMiddlewareInstance
}) => {
  return listener.startListening<any>({
    actionCreator: createAction(actionType),
    effect: effect,
  })
}
