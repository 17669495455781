import {
  isRejectedWithValue,
  type Middleware,
  type MiddlewareAPI,
} from '@reduxjs/toolkit'
import { toastError } from 'utils/toast'

export const errorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      toastError(action?.payload || '')
    }
    return next(action)
  }
