import { CssBaseline } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import NotFound from 'components/NotFound'

import 'App.scss'

const ErrorApp = () => {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default ErrorApp