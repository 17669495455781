export enum EventType {
  Account = 'account',
  Invoice = 'invoice',
  PaymentIntent = 'payment_intent',
  Operation = 'operation',
  WithdrawRequest = 'withdraw_request',
  WithdrawTemplate = 'withdraw_template',
  SendEmail = 'send_email',
  CreateUserWithdrawTemplate = 'CreateUserWithdrawTemplate',
  UpdateUserWithdrawTemplate = 'UpdateUserWithdrawTemplate',
  DeleteUserWithdrawTemplate = 'DeleteUserWithdrawTemplate',
  CreateWithdrawRequest = 'CreateWithdrawRequest',
  Withdraw = 'withdraw',
}
