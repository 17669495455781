import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { ValidateMethodReqData, Verification } from 'types/requests'
import { UserMultiFA, UserProfile } from 'types/user'
import { addSessionId } from 'utils/uuid'
import baseQuery from '../api'

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery,
  tagTypes: ['User', 'QrCode'],
  endpoints: (build) => ({
    fetchUserProfile: build.query<UserProfile, void>({
      query: () => 'user/profile',
      providesTags: () => ['User'],
    }),
    fetchQrCode: build.query<{ url: string }, Verification>({
      query: (data) => ({
        url: 'user/profile/security/multifa/qrcode',
        method: 'POST',
        body: data,
      }),
      providesTags: () => ['QrCode'],
    }),
    validateMethod: build.mutation<void, ValidateMethodReqData>({
      query: (data) => ({
        url: 'user/profile/security/multifa/validate',
        method: 'POST',
        body: addSessionId(data),
      }),
      invalidatesTags: ['User'],
    }),
    setMultiFAMethod: build.mutation<void, UserMultiFA>({
      query: (data) => ({
        url: 'user/profile/security/multifa',
        method: 'POST',
        body: addSessionId(data),
      }),
      invalidatesTags: ['User'],
    }),
  }),
})

export const {
  useFetchUserProfileQuery,
  useFetchQrCodeQuery,
  useValidateMethodMutation,
  useSetMultiFAMethodMutation,
} = userApi
