import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import Preloader from 'components/Preloader'
import Select from 'components/Select'
import GoogleAuth from './GoogleAuth'
import SuccessModal from 'components/SuccessModal'

import { AuthType, authenticationSettings } from 'assets/const'
import { useFetchQrCodeQuery, useValidateMethodMutation } from 'services/user'
import { AuthMethod, UserProfile } from 'types/user'
import { settingsSchema } from './validation'


interface Props {
  user: UserProfile | undefined
}

interface FormValues {
  code: string
  type: AuthMethod
}

const Settings = ({ user }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isValidate, setIsValidate] = useState(false)
  const { data: qrCode, isLoading: isQrLoading } = useFetchQrCodeQuery(
    {
      code: '',
      request: '',
    },
    {
      skip: !user?.id || user?.multiFA?.allowedMethods.includes(AuthType.totp),
    }
  )

  const [validateMethod, { isLoading: isFetchingCode }] =
    useValidateMethodMutation()

  const {
    handleSubmit,
    control,
    resetField,
    setError,
    formState: { errors, isValid, isDirty },
    watch,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(settingsSchema),
    defaultValues: {
      type: authenticationSettings[0].value,
      code: '',
    },
  })

  const currentSelected = watch('type')
  const isAllowMethod = user?.multiFA?.allowedMethods.includes(currentSelected)

  const onSubmit = async (formData: FormValues) => {
    await validateMethod({
      method: formData.type,
      verification: {
        code: formData.code,
        request: '',
      },
    })
      .unwrap()
      .then(() => {
        setIsModalOpen(true)
      })
      .catch(() =>
        setError('code', {
          type: 'validate',
          message: 'Please enter a valid code',
        })
      )
  }

  // const handleDialogClose = () => {
  //   setIsDialogOpen(false)
  // }

  const handleCancel = () => {
    resetField('code')
    setIsValidate(false)
  }

  const handleGoogleValidate = () => {
    setIsValidate(true)
  }

  if (isQrLoading) return <Preloader />

  return (
    <Box
      component={'form'}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box mb={4} display='flex' maxWidth='324px'>
        <Select
          control={control}
          name='type'
          label=''
          options={authenticationSettings}
          errors={errors}
          sx={{maxWidth: '324px'}}
        />
        {isAllowMethod && (
          <CheckCircleRoundedIcon
            color='success'
            sx={{alignSelf: 'center', ml: 1}}
          />
        )}
      </Box>

      {currentSelected === AuthType.totp && qrCode && !isAllowMethod && (
        <GoogleAuth
          qrCode={qrCode.url}
          control={control}
          errors={errors}
          isValidate={isValidate}
          isFetchingCode={isFetchingCode}
          isDirty={isDirty}
          isValid={isValid}
          handleValidate={handleGoogleValidate}
          handleCancel={handleCancel}
        />
      )}

      {/*
      //add auth by phone
      {currentSelected === AuthType.Sms && !isAllowMethod && (
        <Grid item xs={12} sm={10} md={8}>
          <Grid item xs={11}>
            <TextInput
              control={control}
              name='phone'
              label='Phone number'
              errors={errors}
              type='tel'
              required
            />
          </Grid>
          <Grid
            container
            gap={2}
            sx={{
              mt: '25px',
              width: '100%',
              justifyContent: isMobileDevice ? 'center' : 'flex-start',
              aligns: 'center',
            }}
          >
            <Button
              variant='contained'
              fullWidth={isMobileDevice}
              disabled={!isDirty || !isValid}
            >
              Save Phone
            </Button>
            <Button
              variant='contained'
              fullWidth={isMobileDevice}
              disabled={!isDirty || !isValid}
              type='submit'
            >
              Validate 2fa type
            </Button>
          </Grid>
        </Grid>
      )} */}
      {/*  //add auth by email
      {currentSelected === AuthType.Email && !isAllowMethod && (
        <Grid item xs={12} sm={10} md={8}>
          <Grid item xs={11}>
            <TextInput
              control={control}
              name='email'
              label='Email'
              errors={errors}
              type='email'
              required
            />
          </Grid>
          <Button
            sx={{ mt: '25px' }}
            variant='contained'
            fullWidth={isMobileDevice}
            disabled={!isDirty || !isValid}
            type='submit'
          >
            Validate 2fa type
          </Button>
        </Grid>
      )} */}

      {/* <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth={'sm'}
        PaperProps={{
          style: { borderRadius: '20px' },
        }}
      >
        <DialogContent>
          <DialogContentText>
            Google Authenticator method successfully validated! Now you can use
            it for 2-factor authentication{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant='contained' onClick={handleDialogClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog> */}

      {isModalOpen &&
        <SuccessModal
          onClose={() => setIsModalOpen(false)}
          text='Google Authenticator method successfully validated! Now you can use it for 2-factor authentication'
        />
      }
      {isFetchingCode && <Preloader />}
    </Box>
  )
}

export default Settings
