import { FC } from 'react'
import { Grid, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Navigate, } from 'react-router-dom'

import Preloader from 'components/Preloader'
import ScrollableBox from 'components/ScrollableBox'
import Modal from 'components/Modal'
import TransactionsTable from 'components/OperationInfoModal/TransactionsTable'
import TransactionsCards from './TransactionsCard'

import { AppRoute, OperationStatus, OperationType } from 'assets/const'

import { useGetOperationQuery } from 'services/operations'

import { formatAmountForTable, formatDate } from 'utils/intlFormat'
import { statusColors, getOperationValue } from 'utils/operation'

import { useCurrencyByCode } from 'hooks/useCurrencyByCode'
import { useMediaSize } from 'hooks/useMediaSize'

import s from './styles'


interface Props {
  id: string
  onClose: () => void
}

const OperationInfoModal: FC<Props> = ({id, onClose}) => {
  const { data, isLoading, error } = useGetOperationQuery(id ?? '', {
    skip: id === undefined,
  })
  const { currency, isLoadingCurrency } = useCurrencyByCode(data?.currency)

  const {isPhone} = useMediaSize()

  if (isLoading || isLoadingCurrency) return <Preloader />

  if (error) {
    return <Navigate to={`/${AppRoute.NotFound}`} />
  }

  return (
    <Modal open handleClose={onClose} title='Billing information'>
      <Box>
        {/* <Box display='flex' justifyContent='space-between'> */}
          {/*      //V.Didik said hide this element for now
            <Paper
              elevation={0}
              sx={{
                position: 'relative',
                right: { xs: 0, sm: '10%' },
                top: { xs: '20px', sm: '50px' },
                borderRadius: '10px',
                width: 94,
                height: 52,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton color='primary'>
                <IosShareIcon />
              </IconButton>
              <IconButton color='primary'>
                <LocalPrintshopOutlinedIcon />
              </IconButton>
            </Paper> */}
        {/* </Box> */}
        {!!data && (
          <Box sx={s.wrapper}>
            <Grid container sx={s.getGridContainerSx}>
              <Grid item xs={12} sm={6} sx={s.gridItem}>
                <Typography sx={s.label}>Created:</Typography>
                <Typography sx={s.value}>
                  {formatDate(data.created)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={s.gridItem}>
                <Typography sx={s.label}>Operation:</Typography>
                <Typography sx={s.value}>
                  {getOperationValue(data.type, OperationType)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={s.gridItem}>
                <Typography sx={s.label}>Amount:</Typography>
                <Typography sx={s.value}>
                  {formatAmountForTable(data.amount, currency?.symbol)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={s.gridItem}>
                <Typography sx={s.label}>Status:</Typography>
                <Typography
                  sx={{
                    ...s.value,
                    color: statusColors[data.status].main,
                  }}
                >
                  {getOperationValue(data.status, OperationStatus)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{...s.gridItem, flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 0, sm: 1}}}>
                <Typography sx={s.label}>ID:</Typography>
                <Typography sx={s.value}>{data.id}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={s.gridItem}>
                <Typography sx={s.label}>Description:</Typography>
                <Typography sx={s.value}>{data.description}</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {!!data && (
          <>
            <Typography fontWeight={600} mb={2} mt={3} display='inline-block'>
              Transactions:
            </Typography>
            {isPhone ?
              <TransactionsCards items={data.transactions} currencySymbol={currency?.symbol || ''}/>
              :
              <Paper elevation={0} sx={{ ...s.tableWrapper }}>
                <ScrollableBox sx={s.tableContainer}>
                  <TransactionsTable items={data.transactions} currencySymbol={currency?.symbol || ''}/>
                </ScrollableBox>
              </Paper>
            }
          </>
        )}
      </Box>
    </Modal>
  )
}

export default OperationInfoModal
