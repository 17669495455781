import { Dispatch, FC, SetStateAction } from 'react'
import {
  Box,
  Paper,
  Typography,
} from '@mui/material'

import StatusLabel from 'components/StatusLabel'

import { Operation } from 'types/operation'
import { formatAmountForTable, formatDate, formatTime } from 'utils/intlFormat'
import { OperationStatus } from 'assets/const'
import { getOperationValue } from 'utils/operation'
import { theme } from 'theme'


interface Props {
  operation: Operation
  currencySymbol?: string
  setOperationId: Dispatch<SetStateAction<string>>
}

const OperationCard: FC<Props> = ({operation, currencySymbol, setOperationId}) => {
const { id, created, status, type, description, amount } = operation

  return (
    <Paper
      elevation={0}
      sx={{p: 2, mb: 2, cursor: 'pointer', ':hover': {bgcolor: 'primary.light'}}}
      onClick={() => setOperationId(id)}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography fontSize={14}>{formatDate(created)}</Typography>
          <Typography fontSize={12} color={theme.palette.gray.dark}>{formatTime(created)}</Typography>
        </Box>
        <StatusLabel
          status={status}
          sx={{ fontSize: 12, margin: 0 }}
          label={getOperationValue(status, OperationStatus)}
        />
      </Box>
      <Box display='flex' alignItems='end' justifyContent='space-between' mt={2}>
        <Box>
          <Typography fontSize={14}>{type}</Typography>
          <Typography fontSize={12}>{description}</Typography>
        </Box>
        <Typography fontSize={14}>{formatAmountForTable(amount, currencySymbol)}</Typography>
      </Box>
    </Paper>
  )
}

export default OperationCard