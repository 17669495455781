import {
  PaymentFormFieldDefinition,
  PaymentFormFieldValues,
} from 'types/template'
import { capitalize } from './text'

export const getOperationValue = <T extends Record<string, unknown>>(
  value: string,
  obj: T
): string => (value in obj ? String(obj[value]) : capitalize(value))
export const removeUnderscore = (type: string) => type.split('_').join(' ')

interface Colors {
  [key: string]: {
    main: string
    light: string
  }
}

export const statusColors: Colors = {
  created: {main: 'primary.main', light: 'primary.light'},
  declined: {main: 'primary.main', light: 'primary.light'},
  succeeded: {main: 'success.main', light: 'success.light'},
  unblocked: {main: 'success.main', light: 'success.light'},
  confirmed: {main: 'success.main', light: 'success.light'},
  pending: {main: 'pending.main', light: 'pending.light'},
  inProgress: {main: 'pending.main', light: 'pending.light'},
  processedManually: {main: 'pending.main', light: 'pending.light'},
  failed: {main: 'failed.main', light: 'failed.light'},
  blocked: {main: 'failed.main', light: 'failed.light'},
  canceled: {main: 'cancel.main', light: 'cancel.light'},
  none: {main: 'gray.main', light: 'gray.light'}
}

export const getDefaultValues = (
  fields: PaymentFormFieldDefinition[] | undefined
): PaymentFormFieldValues => {
  if (!fields) return {}
  return fields.reduce((values, field) => {
    if (field.fieldName in values) return values
    values[field.fieldName] = field.defaultValue
    return values
  }, {} as PaymentFormFieldValues)
}
