import { FC } from 'react'
import { LinearProgress, Typography } from '@mui/material'
import s from '../styles'

interface Props {
  isFirstStep: boolean
}

const StepsLinearProgress: FC<Props> = ({isFirstStep}) => {
  return (
    <>
      <Typography sx={s.linearProgressText}>{isFirstStep ? 'step 1/2' : 'step 2/2'}</Typography>
      <LinearProgress
        variant='determinate'
        value={isFirstStep ? 50 : 100}
        sx={s.linearProgress}
      />
    </>
  )
}

export default StepsLinearProgress