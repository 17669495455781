import { Button, Menu, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useMemo, useRef, useState } from 'react'
import { profileNavigationItems } from '../ProfileNavigation'
import MobileProfileNavigationItem from './MobileProfileNavigationItem'
import { useLocation } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const MobileProfileNavigation = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation();

  const handleToggle = () => {
    setAnchorEl((prev) => (prev ? null : containerRef.current))
  }

  const activeProfileNavigationItem = useMemo(() =>
    profileNavigationItems.find(item => `/${item.path}` === pathname)
  , [pathname])

  return (
    <>
      <Box sx={{p: {xs: '16px', sm: '20px'}, }}>
        <Box sx={{bgcolor: 'contrast.main', borderRadius: '8px', width: {xs: 'auto', sm: '348px'}}}>
        <Button
          component='div'
          onClick={handleToggle}
          ref={containerRef}
          sx={{
            bgcolor: 'contrast.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Box sx={{display: 'flex', gap: 1, color: 'primary.main' }}>
            {activeProfileNavigationItem?.icon}
            <Typography fontWeight={500}>{activeProfileNavigationItem?.label}</Typography>
          </Box>
          <ArrowDropDownIcon color='primary' sx={{transform: anchorEl ? 'rotate(180deg)' : undefined, transition: '.2s'}}/>
        </Button>
        </Box>
      </Box>

      <Menu
        autoFocus={false}
        id='nav-menu'
        open={!!anchorEl}
        onClick={handleToggle}
        anchorEl={anchorEl}
        MenuListProps={{
          disablePadding: true,
        }}
        PaperProps={{
          elevation: 2,
          sx: {width: {xs: '100%', sm: '348px'}}
        }}
        sx={{width: '100%', mt: '4px'}}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {profileNavigationItems.map(item =>
          <MobileProfileNavigationItem
            key={item.label}
            label={item.label}
            path={item.path}
            icon={item.icon}
          />
        )}
      </Menu>
  </>
  )
}

export default MobileProfileNavigation
