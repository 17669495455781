import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'

import { termsContent } from 'consts/text'

import BackButton from 'components/BackButton'


const Terms = () => {

  return (
    <Container maxWidth='lg' disableGutters>
      <BackButton text='Back' link='/more'/>
      <Typography variant='h1' mb={3}> Terms of Service </Typography>
        <Paper elevation={0} sx={{p: 3, display: 'flex', flexDirection: 'column', gap: 3}}>
        <Typography fontSize={12}> Last updated on 10th of June 2024 </Typography>
        <Typography fontSize={14} textAlign='justify'>
          Welcome to Clever Wallet
          This website: <Link target='_blank' href='https://www.cleverwallet.app'> https://www.cleverwallet.app</Link> (the “Website”) is operated and owned by We Can Develop IT LLC. Clever Wallet is a trading name and registered trademark of We Can Develop IT LLC. These Terms of Service govern your use of our Website, as well as our application available on the Apple App Store and Google Play Store (collectively, the “Platform”).
          We Can Develop IT LLC is a legal entity registered under the law of the USA with its registered number: 88-4253888, address: 12000 Ancient Crest Circle, Apt 12107, Franklin, Tennessee, 37067.
          These Terms of Service (“Terms of Service”, “Terms”,) are between You (the “User”, “You” or “Your”) and We Can Develop IT LLC (“Clever Wallet”, “We”, “Us” or “Our”).
          Please read these Terms of Service carefully before accessing or using our Platform, including our Website and application on the Apple App Store and Google Play Store, or our products and services that we may offer You through the Platform. By accessing or using any part of this Platform, You agree to be bound by these Terms of Service. If You do not agree to all the provisions of these Terms of Service, You may not access or use the Platform.
          We reserve the right to update, amend, or replace any part of these Terms of Service by posting updates and/or changes to this Platform. In order to continue using the Platform, You may be required to read and accept these Terms again once they are updated. When You are using the Platform on behalf of a business, You hereby declare that You have the authority (i) to use the Platform, as well as our products and services on behalf of the business; (ii) to bind that
        </Typography>
        {termsContent.map(content =>
          <Box key={content.title}>
            <Typography fontSize={14} fontWeight={700}>{content.title}</Typography>
            {content.text.map((t, index) =>
              <Typography key={index} fontSize={14} textAlign='justify'>{t}</Typography>
            )}
          </Box>
        )}
        </Paper>
    </Container>
  )
}

export default Terms