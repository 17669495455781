import { baseApiUrl } from 'assets/const'
import { ReactNode, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import AuthService from 'services/AuthService'
import {
  updateSavedTemplateFailedAction,
  updateSavedTemplateSuccessAction,
  withdrawFailedAction,
  withdrawSuccessAction,
} from 'store/actions'
import { EventType } from 'types/sse-type'
import { uuid } from 'utils/uuid'
import { store } from '../store'

const EventSource = require('sse-events')

const SSE_TIMEOUT = 600000
const TOKEN_VALIDITY = 1500

type Props = {
  children: ReactNode
}

const SseProvider = ({ children }: Props) => {
  const eventSource = useRef<any | null>(null)

  useEffect(() => {
    if (eventSource.current) {
      return
    }
    const connect = async () => {
      const token = await AuthService.updateTokenSse(TOKEN_VALIDITY)

      eventSource.current = new EventSource({
        url: `${baseApiUrl}sse?sessionId=${uuid}`,
        options: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: false,
          connectionTimeout: SSE_TIMEOUT,
        },
        retryOnServerError: true,
        retryOnNetworkError: true,
      })

      eventSource.current.addEventListener('open', () => {})

      eventSource.current.addEventListener('state', () => {})

      eventSource.current.addEventListener('error', () => {
        eventSource.current.close()
        delete eventSource.current.options.headers.Authorization
        eventSource.current.retrying = true
        AuthService.updateTokenSse(TOKEN_VALIDITY)
          .then((res) => {
            eventSource.current.setHeaders({ Authorization: `Bearer ${res}` })
            eventSource.current.open()
          })
          .catch(() => null)
        eventSource.current.retrying = false
      })

      eventSource.current.addEventListener('message', (e: any) => {
        console.log('message:', e)
        const data = e.data
        const error = data.payload?.error
        if (error) {
          toast.error(data.payload?.message || error)
        }

        switch (data.type) {
          //different types of payload are coming and therefore I cannot partially update the templates. I have to do a refetch of all
          case EventType.CreateUserWithdrawTemplate:
          case EventType.DeleteUserWithdrawTemplate:
          case EventType.UpdateUserWithdrawTemplate:
          case EventType.WithdrawTemplate:
            error
              ? store.dispatch(updateSavedTemplateFailedAction)
              : store.dispatch(updateSavedTemplateSuccessAction)
            break
          case EventType.Withdraw:
          case EventType.WithdrawRequest:
          case EventType.CreateWithdrawRequest:
            error
              ? store.dispatch(withdrawFailedAction)
              : store.dispatch(withdrawSuccessAction)
            break
          default:
            console.log(data.type)
        }
      })
      eventSource.current.addEventListener('close', (e: any) => {})

      eventSource.current.addEventListener('timeout', (e: any) => {})
      eventSource.current.open()
    }
    connect()
  }, [])

  return <>{children}</>
}

export default SseProvider