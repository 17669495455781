import { Box, Typography } from '@mui/material'
import { ReactComponent as EmptyListIcon } from 'assets/svg/EmptyList.svg'


const WalletsEmptyList = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 3
      }}
    >
      <EmptyListIcon />
      <Typography
        sx={{
          fontSize: '14px',
          textAlign: 'center',
          color: 'gray.main',
          marginTop: '10px',
        }}
      >
        The list is empty
      </Typography>
    </Box>
  )
}

export default WalletsEmptyList
