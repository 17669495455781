import { Box, Button, ButtonGroup, Grid, IconButton } from '@mui/material'
import { useCallback, useState } from 'react'
import Cropper, { Area, Point } from 'react-easy-crop'

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'

import { useMediaSize } from 'hooks/useMediaSize'
import getCroppedImg from 'utils/cropImage'

interface Props {
  image: string
  croppedImage: string | null
  setImage: (image: string | null) => void
  setCroppedImage: (image: string | null) => void
  handleModalClose: () => void
}

const AvatarCropper = ({
  image,
  croppedImage,
  setImage,
  setCroppedImage,
  handleModalClose,
}: Props) => {
  const MIN_ZOOM = 1
  const MAX_ZOOM = 3
  const ZOOM_STEP = 0.5

  const { buttonSize } = useMediaSize()
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [zoom, setZoom] = useState(MIN_ZOOM)

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    []
  )

  const showCroppedImage = useCallback(async () => {
    const currentImage = croppedImage || image

    try {
      const croppedImage = await getCroppedImg(
        currentImage,
        croppedAreaPixels as Area
      )
      setCroppedImage(croppedImage)
      setZoom(MIN_ZOOM)
      handleModalClose()
    } catch (e) {
      console.error(e)
      handleModalClose()
    }
  }, [croppedAreaPixels])

  const handleDelete = () => {
    setImage(null)
    setCroppedImage(null)
  }

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + ZOOM_STEP)
  }

  const handleZoomOut = () => {
    setZoom((prevZoom) => prevZoom - ZOOM_STEP)
  }

  return (
    <>
      <Box sx={{ position: 'relative', width: '100%', height: '400px', my: '24px' }}>
        {croppedImage ? (
          <Box
            height={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
          >
            <img
              src={croppedImage}
              alt='Avatar'
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          </Box>
        ) : (
          <>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape='round'
              showGrid={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <ButtonGroup
              orientation='vertical'
              aria-label='zoom button group'
              sx={{ position: 'absolute', top: '5px', right: '5px' }}
            >
              <IconButton
                disabled={zoom === MAX_ZOOM}
                aria-label='zoom in'
                color='primary'
                onClick={handleZoomIn}
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton
                disabled={zoom === MIN_ZOOM}
                aria-label='zoom out'
                color='primary'
                onClick={handleZoomOut}
              >
                <RemoveCircleOutlineOutlinedIcon />
              </IconButton>
            </ButtonGroup>
          </>
        )}
      </Box>
      <Grid container justifyContent={'space-between'} gap={3}>
        <Button
          variant='contained'
          color='primary'
          size={buttonSize}
          onClick={showCroppedImage}
          disabled={!!croppedImage}
          sx={{flex: 1}}
        >
          Upload
        </Button>
        <Button
          variant='outlined'
          color='primary'
          size={buttonSize}
          onClick={handleDelete}
          sx={{flex: 1}}
        >
          Delete
        </Button>
      </Grid>
    </>
  )
}

export default AvatarCropper
