import { Box, TableCell, Typography } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { ReactComponent as EmptyListIcon } from 'assets/svg/EmptyList.svg'


const EmptyList = () => {
  return (
    <TableRow sx={{ width: '100%', display: 'block' }}>
      <TableCell
        sx={{
          width: '100%',
          height: '100%',
          display: 'block',
          padding: 0,
          border: 0,
        }}
        colSpan={12}
      >
        <Box
          sx={{
            width: '100%',
            height: '290px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EmptyListIcon />
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'center',
              color: 'gray.main',
              marginTop: '10px',
            }}
          >
            The list is empty
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default EmptyList
