import { Box, SxProps } from '@mui/material'
import { statusColors } from 'utils/operation'


interface Props {
  status: string
  sx?: SxProps
  label: string
}

const StatusLabel = ({ status, label, sx }: Props) => {

  return (
    <Box
      sx={{
        margin: '0 auto',
        px: '16px',
        borderRadius: '20px',
        height: '24px',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'capitalize',
        backgroundColor: '#AC7DFF',
        color: statusColors[status].main,
        bgcolor: statusColors[status].light,
        ...sx,
      }}
    >
      {label}
    </Box>
  )
}

export default StatusLabel