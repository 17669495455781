
import { createTheme } from '@mui/material/styles'
import { PaletteColorOptions } from '@mui/material'

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    gradient?: string
  }
  interface PaletteOptions {
    contrast: PaletteColorOptions
    gray: PaletteColorOptions
    cancel: PaletteColorOptions
    failed: PaletteColorOptions
    pending: PaletteColorOptions
    decline: PaletteColorOptions
    description: PaletteColorOptions
    accept: PaletteColorOptions
    created: PaletteColorOptions
    inprogress: PaletteColorOptions
    confirmed: PaletteColorOptions
    declined: PaletteColorOptions
  }

  interface Palette {
    contrast: PaletteColor
    gray: PaletteColor
    cancel: PaletteColor
    failed: PaletteColor
    pending: PaletteColor
    decline: PaletteColor
    description: PaletteColor
    lightIndigo: PaletteColor
    accept: PaletteColor
    created: PaletteColor
    inprogress: PaletteColor
    confirmed: PaletteColor
    declined: PaletteColor
  }

  interface SimplePaletteColorOptions {
    gradient?: string
  }

  interface TypeText {
    contrast: string
    green: string
  }
}

declare module '@mui/material/Pagination' {
  interface PaginationPropsColorOverrides {
    lightIndigo: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    contrast: true
    gray: true
  }
  interface ButtonPropsVariantOverrides {
    contrast: true
    textOutlined: true
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    contrast: true
    gray: true
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1156,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: '#5260FE',
      light: '#EBF3FF',
      gradient: 'linear-gradient(#6312F3, #3F03A9)',
    },
    secondary: {
      main: '#7a7a7a',
      light: '#D2D2D2',
    },
    contrast: {
      main: '#FFFFFF',
      light: '#F0F3F6',
      dark: '#F2F2F2',
      contrastText: '#262626',
    },
    gray: {
      main: '#BDBDBD',
      dark: '#79747E',
      light: '#F5F5F5',
      contrastText: '#212121',
    },
    success: {
      main: '#35AF60',
      light: '#DCFFDD',
    },
    created: { main: '#FAFAFA', light: '#35AF60' },
    inprogress: { main: '#FFB302', light: '#FFEAB9' },
    confirmed: { main: '#35AF60', light: '#DCFFDD' },
    declined: { main: '#FF7A68', light: '#FFE9E6' },
    cancel: {
      main: '#A4ABB6',
      light: '#EBF3FF',
    },
    accept: {
      main: '#FAFAFA',
      light: '#35AF60',
    },
    failed: {
      main: '#DD5757',
      light: '#FFDCDC',
    },
    pending: {
      main: '#FFB302',
      light: '#FFEAB9',
    },
    decline: {
      main: '#A4ABB6',
      light: '#EBF3FF',
    },
    description: {
      main: '#0094FF',
    },
    text: {
      primary: '#262626',
      secondary: '#BDBDBD',
      contrast: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontSize: 14,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: '0px 0px 10px 1px #00000020',
          borderRadius: '8px',

          '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          },
          '& .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today': {
            borderColor: theme.palette.primary.main,
          },
          '& .PrivatePickersYear-root': {
            '& .PrivatePickersYear-yearButton.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          letterSpacing: -1,
          '&.MuiTypography-h1': {
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: '46px',
            color: '#262626',
            [theme.breakpoints.down('md')]: {
              fontSize: '32px',
            },
          },
          '&.MuiTypography-h2': {
            fontWeight: 500,
            fontSize: '24px',
            // [theme.breakpoints.down('md')]: {
            //   fontSize: '18px',
            // },
          },
          '&.MuiTypography-h3': {
            fontWeight: 600,
            fontSize: '18px',
          },
          '&.MuiTypography-h4': {
            fontWeight: 500,
            fontSize: '16px',
          },
          '&.MuiTypography-h5': {
            fontWeight: 400,
            fontSize: '14px',
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#262626',
          '&.MuiOutlinedInput-root': {
            borderRadius: '8px',
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '8px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: -1,
          minHeight: '56px',
          fontSize: '16px',
          textTransform: 'capitalize',
          fontWeight: 500,
          borderRadius: '8px',
          padding: '13px 22px',
        },
        sizeLarge: {
          minHeight: '80px',
          fontSize: '20px',
        },
        sizeSmall: {
          minHeight: '48px',
          padding: '6px 15px',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: ({ theme }) => ({
            '&.Mui-disabled': {
              backgroundColor: `${theme.palette.primary.main}99`,
              color: theme.palette.text.contrast
            }
          }),
        },
        {
          props: { variant: 'textOutlined' },
          style: ({ theme }) => ({
            minWidth: '100px',
            color: theme.palette.contrast.contrastText,
            backgroundColor: theme.palette.text.contrast,
            border: `1px solid ${theme.palette.primary.light}`,
            '&:hover': {
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.contrast.light,
            },
          }),
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          height: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: '#262626',
          border: '1px transparent solid',
          borderRadius: '4px',
          padding: '6px',
          'a': {
            width: '100%',
            height: '100%',
          },
          ':hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
          },
          '&.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
          },
          '&.Mui-selected.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.main,
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        icon: ({ theme }) => ({ fill: theme.palette.primary.main }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: '52px',
          fontSize: '14px',
          fontWeight: 500,
          zIndex: 100,
          position: 'sticky',
          '& .MuiTableCell-root': {
            padding: '14px 16px',
          }
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& td': {
            border: 0,
          },
          '& th': {
            border: 0,
          },
          borderRadius: '8px',
          maxHeight: '58px',
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(99, 201, 213, 0.1)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '52px',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px 8px',
          fontWeith: 'inherit',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '& .MuiTypography-root': {
            fontSize: '14px',
            fontWeith: 'inherit',
            overflowX: 'hidden',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('xs')]: {
            fontWeight: 500,
            fontSize: '14px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            fontWeight: 500,
          },
          lineHeight: '16px',
          textDecoration: 'none',
          padding: '15px 10px',
          minWidth: '220px',
          textAlign: 'center',
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textTransform: 'capitalize',
          letterSpacing: '-1',
          color: theme.palette.gray.dark,
          '&.Mui-selected': {
            borderBottom: '3px #7B18F5 solid',
            [theme.breakpoints.up('sm')]: {
              fontWeight: 600,
            },
          },
        }),
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiButtonBase-root:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .MuiPaginationItem-firstLast': {
            backgroundColor: theme.palette.gray.light,
          },
          '& .MuiPaginationItem-previousNext': {
            backgroundColor: theme.palette.gray.light,
          },
        }),
      },
    },
  },
})