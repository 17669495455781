import { Box, Paper, Typography } from '@mui/material'
import { useMediaSize } from 'hooks/useMediaSize'
import { FC, ReactNode } from 'react'


interface Props {
  title: string
  children: ReactNode
}

const ProfileLayout: FC<Props> = ({ title, children }) => {
  const {isPhone} = useMediaSize()

  return (
    <Paper elevation={0} sx={{maxWidth: '1056px', padding: {xs: 2, sm: 3}}}>
      <Typography variant={isPhone ? 'h3' : 'h2'}>
        {title}
      </Typography>
      <Box>
        {children}
      </Box>
    </Paper>
  )
}

export default ProfileLayout