import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import BackButton from 'components/BackButton'
import Preloader from 'components/Preloader'
import StripeDialog from 'components/StripeDialog'
import AccountTransfer from './AccountTransfer'
import SuccessModal from 'components/SuccessModal'
import SecurityModal from './AccountTransfer/SecurityModal'

import { useMediaSize } from 'hooks/useMediaSize'
import { useTransferExternalMutation, useTransferInternalMutation } from 'services/operations'
import { useFetchAllWalletsQuery, useGetWalletQuery } from 'services/wallets'
import { useFetchUserProfileQuery } from 'services/user'
import { useFetchAllCurrenciesQuery } from 'services/dictionaries'
import { TransferReqData } from 'types/requests'


const Transfer = () => {
  const { isPhone } = useMediaSize()

  const [searchParams] = useSearchParams()
  const walletId = searchParams.get('wallet')
  const currencyCode = searchParams.get('currency') || ''
  const [modal, setModal] = useState<'SUCCESS_MODAL' | 'SECURITY_MODAL' | null>(null)
  const [transferData, setTransferData] = useState<TransferReqData | null>(null)

  const [transferExternal, {isLoading: isTransferExternalLoading}] = useTransferExternalMutation()
  const [transferInternal, {isLoading: isTransferInternalLoading}] = useTransferInternalMutation()


  const { data: wallets, isLoading: isLoadingWallets } = useFetchAllWalletsQuery({currency: currencyCode})
  const { data: user, isLoading: isLoadingUser } = useFetchUserProfileQuery()
  const { data: currencies = [] } = useFetchAllCurrenciesQuery()
  const { data: wallet, isLoading: isLoadingWallet } = useGetWalletQuery(
    walletId ?? '',
    {
      skip: !walletId,
    }
  )

  const currency = currencies?.find((c)=>(c.code === currencyCode))

  const isLoading = isLoadingWallets || isLoadingWallet || isLoadingUser || !wallets || !wallet || !currency || !user

  const is2faEnabled = !!user?.multiFA?.type && user?.multiFA?.type !== 'none'

  const handleAccountSubmit = async (data: TransferReqData) => {
    if (!data) return
    if (!is2faEnabled) {
      data.recipient ?
      await transferExternal(data)
        .unwrap()
        .then(() => {
          setModal('SUCCESS_MODAL')
        })
        :
        await transferInternal(data)
          .unwrap()
          .then(() => {
            setModal('SUCCESS_MODAL')
          })
    } else {
      setTransferData(data)
    }
  }

  const handleSecuritySubmit =  () => {
    // const handleSecuritySubmit =  (verification: Verification) => {
    if (!transferData) return
    // const data = { ...transferData, verification }
    // await transfer(data)
    //   .unwrap()
    //   .then(() => {
    //     setActivePage(OperationTabLabel.Done)
    //   })
  }

  if (isLoading) return <Preloader />

  return (
    <>
      <Container maxWidth={isPhone ? 'sm' : 'lg'} disableGutters>
        <BackButton/>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {xs: 'center', md: 'start'},
            ml: {sm: 0, md: 9}
          }}
        >
          <AccountTransfer
            wallet={wallet}
            wallets={wallets}
            currency={currency}
            handleTransferSubmit={handleAccountSubmit}
          />
        </Box>
        <StripeDialog
          walletType={wallet.type}
          connectedAccountId={
            user.businessSettings.paymentSettings.stripe.connectedAccountId
          }
        />
      </Container>
      {modal === 'SUCCESS_MODAL' && <SuccessModal onClose={() => setModal(null)}/>}
      {modal === 'SECURITY_MODAL' && (
        <SecurityModal
          handleSecuritySubmit={handleSecuritySubmit}
          loading={isTransferExternalLoading || isTransferInternalLoading}
          onClose={() => setModal(null)}
        />
      )}
    </>
  )
}

export default Transfer