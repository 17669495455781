import { Box, Grid, Typography } from '@mui/material'
import BackButton from 'components/BackButton'

const NotFound = () => {
  return (
    <Box mt='50px'>
      <Typography variant='h1' textAlign={'center'}>
        The Page you’re looking for doesn’t exist.
      </Typography>
      <Grid container justifyContent={'center'}>
        <BackButton />
      </Grid>
      {/* <Link to="/" style={{ color: 'red', fontSize: '20px', fontWeight: 800 }}>To Home Page</Link> */}
    </Box>
  )
}

export default NotFound