import { FieldErrors, FieldValues } from 'react-hook-form'

export const capitalize = (str: string) =>
  str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const separateBit = (str: string) =>
  str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const getDefaultName = (currency: string = ''): string =>
  `${currency} Wallet`

export const getErrorMessage = (
  errors: FieldErrors<FieldValues>,
  touchedFields: { [key: string]: string }
): string =>
  Object.keys(errors).reduce((sum, name) => {
    if (!touchedFields[name]) return sum
    const message = errors[name]?.message?.toString()
    if (!message) return sum
    const isIncludes = sum.includes(message)
    if (isIncludes) return sum
    return sum.concat(' ', message)
  }, '')
