import { Avatar, Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { KeyboardEvent, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import AuthService from 'services/AuthService'
import { getAvatarInitials, getGravatarURL } from 'utils/avatar'
import { AppRoute, portalUiUrl } from 'assets/const'

import { ReactComponent as MyProfileIcon } from 'assets/svg/buttons/MyProfile.svg'
import { ReactComponent as SignOutIcon } from 'assets/svg/buttons/SignOut.svg'
import { ReactComponent as SettingsIcon} from 'assets/svg/buttons/Settings.svg'

import { useMediaSize } from 'hooks/useMediaSize'
import { theme } from 'theme'

import s from '../styles'


const UserInfo = () => {
  const { isPhone } = useMediaSize()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const avatarUrl = getGravatarURL(AuthService.getUserEmail())

  const handleToggle = () => {
    setAnchorEl((prev) => (prev ? null : containerRef.current))
  }

  const handleTabPress = (event: KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleSignOut = () => AuthService.doLogout()

  return (
    <>
      <Tooltip title='Open settings' tabIndex={0}>
        <Box
          role='button'
          component={'button'}
          aria-haspopup='true'
          sx={s.userMenuWrapper}
          onClick={handleToggle}
          ref={containerRef}
        >
          <Avatar alt='Avatar' src={avatarUrl} sx={s.avatar}>
            <Typography variant='body2'>
              {getAvatarInitials(
                AuthService.getUserFirstName(),
                AuthService.getUserLastName()
              )}
            </Typography>
          </Avatar>

          {!isPhone &&
            <Box ml={2} flexShrink={1} >
              <Typography sx={s.textName}>
                {AuthService.getUserName() ?? ''}
              </Typography>
              <Typography sx={s.textMail}>
                {AuthService.getUserEmail() ?? ''}
              </Typography>
            </Box>
          }
        </Box>
      </Tooltip>
      <Menu
        autoFocus={false}
        id='user-menu'
        open={isOpen}
        onClose={handleToggle}
        anchorEl={anchorEl}
        MenuListProps={{ disablePadding: true, sx: s.userMenuList }}
        PaperProps={{
          elevation: 2,
          sx: s.getUserMenuPaper(containerRef),
        }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem
          divider
          onClick={handleToggle}
          onKeyDown={handleTabPress}
          sx={s.userMenuItem}
          tabIndex={-1}
        >
          <Link to={AppRoute.Profile}>
            <MyProfileIcon color={theme.palette.primary.main}/>
            My Profile
          </Link>
        </MenuItem>
        <MenuItem
          divider
          onClick={handleToggle}
          onKeyDown={handleTabPress}
          sx={s.userMenuItem}
          tabIndex={-1}
        >
          <Link to={`${portalUiUrl}${AppRoute.Settings}/${AppRoute.Payment}?returnUrl=${window.location.href}`}>
            <SettingsIcon color={theme.palette.primary.main}/>
            Payment settings
          </Link>
        </MenuItem>
        <MenuItem
          onClick={handleSignOut}
          onKeyDown={handleTabPress}
          sx={s.userMenuItem}
          tabIndex={0}
        >
          <Box>
            <SignOutIcon color={theme.palette.primary.main}/>
            Sign Out
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserInfo
