export const faqContent = [
  {
    title: 'What is Clever Wallet?',
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'
  },
  {
    title: 'How can I get started?',
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'
  },
  {
    title: 'What industries do you serve?',
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'
  },
  {
    title: 'How can I contact you?',
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'
  },
  {
    title: 'Still have questions?',
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore'
  },
]

export const aboutUsContent = [
  'Our experts have something very specific in common - a passion for learning. To be one of our experts, you love to learn, you are on the cutting edge and stay there, you learn new things, you are a true expert - a pro.',
  'Our experts have been amazing clients in the United States and throughout Europe for more than 15 years. We bring to our work the discipline of process and risk management.',
  'Our relationships with hosting providers world-wide equip us to not only build the solution for you, but then to guide and support you in deployment, business practice integration and on-going customer service and system support.',
  'The secret to our experts’ success is the unity and cohesiveness of our team. The collective experience of our 30 some odd experts in every discipline within the software development process comes to bear to ensure your project is a success.'
]

export const termsContent = [
  {
    title: '1. Using of the platform',
    text: [
      'This Platform may only be used by natural persons (individuals) and companies who have the legal capacity to enter into legally binding contracts under the law applicable in their country of residence. By agreeing to these Terms of Service, You represent that You are of legal age in Your country of residence. You may not use this Platform and/or any of our products and services provided by this Platform for any illegal or unauthorized purpose.',
    ]
  },
  {
    title: '2. Accuracy of the content of the platform',
    text: [
      'All content shown on this Platform, along with all information about our products or services, is provided by us and made available to You without any warranties, conditions, or guarantees. All such warranties, conditions, or guarantees may be implied only by law and to the extent permitted by law. In particular, we do not guarantee the accuracy, reliability, performance, completeness, or suitability for the purpose of any information, services, or products provided by us to You through the Platform.',
      'We will use our best efforts to ensure that any information placed on the Platform is obtained from resources likely to be reliable. We have no obligation to update any information on the Platform and do not guarantee either completeness or accuracy of such information. We shall not be responsible for any use or interpretation of such information. None of the information constitutes legal, financial, or investment advice.',
      'All the information placed on this Platform is provided exclusively for Your guidance and should not be relied upon or used as the sole basis for making decisions. You use the Platform as well as all the information provided on it solely at Your own risk.'
    ]
  },
  {
    title: '3. Errors, inaccuracies, and omissions',
    text: [
      'Occasionally there may be information on this Platform or through the Services that may contain typographical errors, inaccuracies, or omissions that may relate to services and/or product descriptions, pricing, promotions, offers, or products and services availability. We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update information on this Platform at any time without prior notice. We undertake no obligation to update, amend, or clarify information on this Platform or related products or services, including, without limitation, pricing information, except as required by law.',
    ]
  },
  {
    title: '4. Intellectual property',
    text: [
      'All materials contained on this Platform, including without limitation all texts, articles, images, and logos, are owned by us and constitute the intellectual property of We Can Develop IT LLC. The materials may not be copied or redistributed for commercial or private purposes of any kind without prior written permission from We Can Develop IT LLC. By providing You with access to this Platform, we grant You a non-transferable, limited personal license. This license shall be deemed valid only in case of Your full compliance with these Terms of Service.',
    ]
  },
  {
    title: '5. Using of the platform',
    text: [
      'In addition to other prohibitions as may be set forth in these Terms of Service, You are strictly prohibited from using this Platform or any of its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any national or international rules, laws, or regulations; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to submit false or misleading information; (f) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Platform or Services or of any related website, other websites, or the Internet; (g) to collect or track the personal information of others; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to block Your use of this Platform for violating any of the prohibited uses and/or seek judicial remedy for the protection of any of our rights that were violated by You.',
    ]
  },
  {
    title: '6. Third - party links',
    text: [
      'The content of the Platform may include materials from third-parties. Third-party links on the Platform may direct You to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating any third-party content or its accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.',
      'We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure You understand them before You engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to such third-party.'
    ]
  },
  {
    title: '7. Optional tools',
    text: [
      'Through the Platform, we may provide You with access to third-party tools over which we neither monitor nor have any control nor input (“third party tools”, “optional tools”). You acknowledge and agree that we provide access to such optional tools ”as is” and “as available” without any warranties, representations, or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to Your use of optional tools. Any use by You of optional tools offered through this Platform is entirely at Your own risk and discretion and You should ensure that You are familiar with and approve of the terms on which tools are provided by the relevant third-party providers.',
      'We may also at any time thereafter offer new services and/or features through the Platform (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.'
    ]
  },
  {
    title: '8. Advertising disclaimer',
    text: [
      'Under no circumstances shall any advertisements, catalogs, brochures, or any announcements published on this Platform constitute a binding contract or an offer to enter into any binding contract. We Can Develop IT LLC reserves the right to revoke, amend, terminate, change, discontinue, delete, update, limit, or extend any advertisement material at any time at its own discretion.',
    ]
  },
  {
    title: '9. Personal data',
    text: [
      'We Can Develop IT LLC processes information about You in accordance with its Privacy Policy. Please note that due to the nature of its business, We Can Develop IT LLC may request You to provide data (including personal data) in order to perform KYC/AML/CFT checks before being able to provide you access to our products and services. We always process any personal data about You in accordance with applicable law and our privacy policy. You have to accept our Privacy Policy before using this Platform, our products, or services.',
    ]
  },
  {
    title: '10. Indemnification',
    text: [
      'You agree to indemnify, defend and hold harmless We Can Develop IT LLC and our affiliates (including without limitation parent companies, subsidiaries, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees) from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of Your breach of these Terms of Service.',
    ]
  },
  {
    title: '11. Severability',
    text: [
      'In the event that any provision of these Terms of Service is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service; such determination shall not affect the validity and enforceability of any other remaining provisions.',
    ]
  },
  {
    title: '12. Termination',
    text: [
      'These Terms of Service are effective unless and until terminated by either You or us. You may terminate these Terms of Service at any time by notifying us that You no longer wish to use the Platform, our products, or services.',
      'We reserve the right to suspend or terminate Your use of the Platform in cases when we know You have failed or we have grounds to consider You have failed, or we suspect that You have failed to comply with any term or provision of these Terms of Service or Privacy Policy.',
    ]
  },
  {
    title: '13. Entire acreement',
    text: [
      'Our failure to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. These Terms of Service and any policies or operating rules posted by us on this Platform or in respect to our products or services or in respect of any other products or services we may provide to You through the Platform, constitute the entire agreement and understanding between You and us. Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.',
    ]
  },
  {
    title: '14. Governing law',
    text: [
      'These Terms of Service and any separate agreements whereby we provide You Services shall be governed by and construed in accordance with the laws of the USA.',
      'SECTION 15 – CONTACT DETAILS',
      'Any and all questions as regard to these Terms of Service shall be sent to our email address: support@cleverwallet.com.'
    ]
  },
  {
    title: '15. Contact details',
    text: [
      'Any and all questions as regard to these Terms of Service shall be sent to our email address: support@cleverwallet.com.',
    ]
  }
]