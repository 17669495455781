import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Button } from '@mui/material'
import Modal from 'components/Modal'

import { useMediaSize } from 'hooks/useMediaSize'


interface Props {
  onConfirm: () => void
  onClose: () => void
  variant: 'delete' | 'confirm'
  confirmButtonText?: string
  cancelButtonText?: string
  loading?: boolean
  text: string
}

const ConfirmModal: FC<Props> = ({
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  loading,
  onClose,
  onConfirm,
  variant,
  text,
}) => {
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onConfirm()
  }

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClose()
  }

  const confirmButtonColor = variant === 'delete' ? 'error' : 'primary'
  const {isPhone} = useMediaSize()
  return (
    <Modal
      open={true}
      handleClose={onClose}
    >
      <Box minWidth='350' maxWidth='450px'>
        <Typography
          fontWeight={isPhone ? 400 : 500}
          fontSize={isPhone ? '12px' : '16px'}
          textAlign='center'
        >
          {text}
        </Typography>

        <Box mt={3} display='flex' gap={3}>
          <Button
            disabled={loading}
            onClick={onSubmit}
            variant='outlined'
            color={confirmButtonColor}
            fullWidth
          >
            {confirmButtonText}
          </Button>
          <Button onClick={onCancel} variant='contained' fullWidth >
            {cancelButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>


  )
}

export default ConfirmModal