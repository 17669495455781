import { FC } from 'react'
import { Link, useMatch } from 'react-router-dom'
import { MenuItem } from '@mui/material'
import { MenuLinkProps } from '../MenuLink'

import s from '../../styles'


const MobileMenuLink: FC<MenuLinkProps> = ({label, path, disabled}) => {
  const match = useMatch({
    path: path,
    end: true,
  })

  return (
    <MenuItem sx={s.mobileLink(!!match, !!disabled)} tabIndex={-1}>
      <Link to={path}>
        {label}
      </Link>
    </MenuItem>


  )
}

export default MobileMenuLink
