import { SxProps } from '@mui/material'

const wrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100dvh',
}

const container: SxProps = {
  padding: {xs: '16px', sm: '20px', md: '24px'},
  display: 'flex',
  flexDirection: { xs: 'column', md : 'row',},
  mx: 'auto',
  flexGrow: '1',
  width: '100%',
  maxWidth: '1360px',
  '& *': {
    scrollMarginTop: { xs: '45px', sm: '80px' },
  },
}

const Styles = {
  wrapper,
  container,
}

export default Styles
