import { SxProps, Theme } from '@mui/material'

import Vector from 'assets/images/Vector.png'
import { theme } from 'theme'


const container: SxProps = {
  minWidth: '487px',
  height: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const content: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  width: '100%',
}

const linearProgress: SxProps = {
  width: '100%',
  mb: 2
}

const linearProgressText: SxProps = {
  width: '100%',
  fontSize: '12px',
  color: theme.palette.primary.main,
  ml: 1
}

const fitstStepBox: SxProps = {
  flex: 1,
  height: '100%'
}

const selectPaymentText: SxProps = {
  flex: 1,
  mb: 1
}

const tabsWrapper: SxProps = {
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  flex: 1,
}

const walletCard = (showBackground?: boolean): SxProps<Theme> => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mr: '12px',
  height: '52px',
  width: '82px',
  borderRadius: '8px',
  background: (theme) =>
    showBackground
      ? `url(${Vector}) no-repeat right, ${theme.palette.gray.main}`
      : 'none',
  })

const getWalletButton = (isActive: boolean): SxProps => ({
  p: { sm: '20px 24px' },
  minHeight: { xs: '77px', sm: '92px' },
  maxWidth: '100%',
  justifyContent: 'flex-start',
  border: '1px transparent solid',
  borderRadius: '8px',
  backgroundColor: 'transparent' ,
  borderColor: isActive ? 'primary.main' : 'transparent',
  ':hover': {
    bgcolor: 'primary.light',
  }
})

const header: SxProps = {
  fontSize: '18px',
  fontWeight: '600',
  textAlign: 'center'
}

const walletName: SxProps<Theme> = {
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: { xs: '22px', sm: '44px', md: '22px' },
  display: 'inline-block',
  color: (theme) => theme.palette.primary.main,

}

const cardCurrency: SxProps = {
  fontSize: '24px' ,
  fontWeight: '600',
}

const closeButton = {
  position: 'absolute',
  top: { xs: '12px', sm: '24px' },
  right: { xs: '12px', sm: '24px' },
}

const buttonsContainer = {
  display: 'flex',
  gap: '24px',
  mt: '24px',
  width: '100%'
}

const button = {
  flex: '1',
}

const icon: SxProps = {
  bgcolor: 'contrast.main',
  width: '100%',
  height: '100%',
  border: '1px solid',
  borderColor: 'primary.light',
  borderRadius: '8px',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  '& > img': {
    maxHeight: '90%',
    maxWidth: '90%',
  }
}

const Styles = {
  container,
  content,
  fitstStepBox,
  linearProgress,
  linearProgressText,
  selectPaymentText,
  walletCard,
  tabsWrapper,
  getWalletButton,
  header,
  closeButton,
  button,
  buttonsContainer,
  walletName,
  cardCurrency,
  icon
}

export default Styles
