import { toast } from 'react-toastify'

const formatMessage = (message: string) => {
  const index = message.indexOf(':')
  return index === -1 ? message : message.slice(index + 1).trim()
}

export const errCatch = (error: any): string =>
  error && error.data
    ? typeof error.data === 'object'
      ? formatMessage(error.data?.reason || error.data?.message)
      : error.data
    : error.error

export const toastError = (error: any, message?: string) => {
  const errorMessage = errCatch(error)
  toast.error(message || errorMessage)
}